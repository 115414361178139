// FinancialPlanner.js
import React, { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import Layout from '../../../features/dashboard/Layout';
import IncomeProfileEditor from './IncomeProfileEditor';
import IncomeTuning from './IncomeTuning';
import IncomeViewer from './IncomeViewer';
import GeneralInformationTab from './GeneralInformationTab';
import DataEntryTab from './DataEntryTab';
import ResultsTab from './ResultsTab';

const FinancialPlanner = () => {
  const [isIncomeProfileModalOpen, setIsIncomeProfileModalOpen] = useState(false);
  const [isTuneIncomeModalOpen, setIsTuneIncomeModalOpen] = useState(false);
  const [isViewIncomeModalOpen, setIsViewIncomeModalOpen] = useState(false);
  const [isResultsModalOpen, setIsResultsModalOpen] = useState(false);

  const [originalIncomeProfile, setOriginalIncomeProfile] = useState(null);
  const [adjustedIncomeProfile, setAdjustedIncomeProfile] = useState(null);

  const [generalInfo, setGeneralInfo] = useState({
    scenarioName: '',
    birthYear: new Date().getFullYear() - 30,
    retirementAge: 65,
    runoutAge: 85,
  });

  const [timeHorizon, setTimeHorizon] = useState(20);
  const [timeBeforeRetirement, setTimeBeforeRetirement] = useState(35);
  const [upperIncomeLimit, setUpperIncomeLimit] = useState(120000);
  const [calculationResults, setCalculationResults] = useState(null);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const currentAge = currentYear - generalInfo.birthYear;
    const newTimeHorizon = generalInfo.runoutAge - currentAge;
    const newTimeBeforeRetirement = Math.max(0, generalInfo.retirementAge - currentAge);
    setTimeHorizon(newTimeHorizon);
    setTimeBeforeRetirement(newTimeBeforeRetirement);
  }, [generalInfo]);

  // Synchronize income profiles with timeBeforeRetirement and timeHorizon
  useEffect(() => {
    const adjustIncomeProfiles = () => {
      // Adjust originalIncomeProfile.values to match timeBeforeRetirement
      if (originalIncomeProfile) {
        let originalValues = originalIncomeProfile.values.slice(0, timeBeforeRetirement);
        while (originalValues.length < timeBeforeRetirement) {
          originalValues.push(50000); // Default income value or any desired default
        }
        setOriginalIncomeProfile({
          ...originalIncomeProfile,
          values: originalValues,
        });
      }

      // Adjust adjustedIncomeProfile.values to match timeHorizon
      if (adjustedIncomeProfile) {
        let adjustedValues = adjustedIncomeProfile.values.slice(0, timeHorizon);
        while (adjustedValues.length < timeHorizon) {
          adjustedValues.push(0); // Default income after retirement is 0
        }
        setAdjustedIncomeProfile({
          ...adjustedIncomeProfile,
          values: adjustedValues,
        });
      }
    };

    adjustIncomeProfiles();
  }, [timeBeforeRetirement, timeHorizon]);

  const handleGeneralInfoChange = (newInfo) => {
    setGeneralInfo(newInfo);
  };

  const handleSaveIncomeProfile = (newIncomeProfile) => {
    // Ensure originalIncomeProfile.values has length timeBeforeRetirement
    let originalValues = newIncomeProfile.values.slice(0, timeBeforeRetirement);
    while (originalValues.length < timeBeforeRetirement) {
      originalValues.push(50000); // Default income value or any desired default
    }
    const updatedOriginalIncomeProfile = {
      ...newIncomeProfile,
      values: originalValues,
    };
    setOriginalIncomeProfile(updatedOriginalIncomeProfile);

    // Adjust adjustedIncomeProfile.values to match timeHorizon
    let adjustedValues = adjustedIncomeProfile ? adjustedIncomeProfile.values.slice(0, timeHorizon) : [];
    while (adjustedValues.length < timeHorizon) {
      adjustedValues.push(0); // Default income after retirement is 0
    }
    // Fill in pre-retirement values from original income profile
    for (let i = 0; i < timeBeforeRetirement; i++) {
      adjustedValues[i] = originalValues[i];
    }
    setAdjustedIncomeProfile({
      values: adjustedValues,
    });

    setIsIncomeProfileModalOpen(false);
  };

  const handleSaveIncomeTuning = (newAdjustedIncomeProfile) => {
    // Ensure adjustedIncomeProfile.values has length timeHorizon
    let adjustedValues = newAdjustedIncomeProfile.values.slice(0, timeHorizon);
    while (adjustedValues.length < timeHorizon) {
      adjustedValues.push(0); // Default income after retirement is 0
    }
    setAdjustedIncomeProfile({
      ...newAdjustedIncomeProfile,
      values: adjustedValues,
    });
    setIsTuneIncomeModalOpen(false);
  };

  const handleCalculate = () => {
    if (!adjustedIncomeProfile) {
      alert('Please adjust your income profile before calculating results.');
      return;
    }

    // Placeholder for calculation logic
    const results = {
      totalIncome: adjustedIncomeProfile.values.reduce((sum, value) => sum + value, 0),
      averageIncome:
        adjustedIncomeProfile.values.reduce((sum, value) => sum + value, 0) /
        adjustedIncomeProfile.values.length,
      // Add more calculated results as needed
    };
    setCalculationResults(results);
    setIsResultsModalOpen(true);
  };

  return (
    <Layout>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">Financial Planner</h1>
        <Tab.Group>
          <Tab.List className="flex rounded-xl bg-blue-900/20 p-1">
            {['General Information', 'Data Entry', 'Results'].map((category) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  `w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700
                  ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2
                  ${
                    selected
                      ? 'bg-white shadow'
                      : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                  }`
                }
              >
                {category}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="mt-2">
            <Tab.Panel className="rounded-xl bg-white p-3 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2">
              <GeneralInformationTab
                generalInfo={generalInfo}
                onInfoChange={handleGeneralInfoChange}
              />
            </Tab.Panel>
            <Tab.Panel className="rounded-xl bg-white p-3 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2">
              <DataEntryTab
                timeHorizon={timeHorizon}
                timeBeforeRetirement={timeBeforeRetirement}
                upperIncomeLimit={upperIncomeLimit}
                setUpperIncomeLimit={setUpperIncomeLimit}
                setIsIncomeProfileModalOpen={setIsIncomeProfileModalOpen}
                setIsTuneIncomeModalOpen={setIsTuneIncomeModalOpen}
                setIsViewIncomeModalOpen={setIsViewIncomeModalOpen}
                originalIncomeProfile={originalIncomeProfile}
                adjustedIncomeProfile={adjustedIncomeProfile}
              />
            </Tab.Panel>
            <Tab.Panel className="rounded-xl bg-white p-3 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2">
              <ResultsTab
                handleCalculate={handleCalculate}
                adjustedIncomeProfile={adjustedIncomeProfile}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>

      <IncomeProfileEditor
        isOpen={isIncomeProfileModalOpen}
        onClose={() => setIsIncomeProfileModalOpen(false)}
        onSave={handleSaveIncomeProfile}
        initialIncomeProfile={originalIncomeProfile}
        timeHorizon={timeBeforeRetirement}
        upperIncomeLimit={upperIncomeLimit}
      />

      <IncomeTuning
        isOpen={isTuneIncomeModalOpen}
        onClose={() => setIsTuneIncomeModalOpen(false)}
        onSave={handleSaveIncomeTuning}
        originalIncomeProfile={originalIncomeProfile}
        adjustedIncomeProfile={adjustedIncomeProfile}
        timeHorizon={timeHorizon}
        timeBeforeRetirement={timeBeforeRetirement}
      />

      <IncomeViewer
        isOpen={isViewIncomeModalOpen}
        onClose={() => setIsViewIncomeModalOpen(false)}
        adjustedIncomeProfile={adjustedIncomeProfile}
      />

      {/* Results Modal */}
      {isResultsModalOpen && calculationResults && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg w-full max-w-md mx-auto">
            <h2 className="text-2xl font-bold mb-4">Calculation Results</h2>
            <div className="space-y-2">
              <p>
                <strong>Total Income:</strong> ${calculationResults.totalIncome.toLocaleString()}
              </p>
              <p>
                <strong>Average Income:</strong> $
                {calculationResults.averageIncome.toLocaleString()}
              </p>
              {/* Add more result fields here */}
            </div>
            <button
              onClick={() => setIsResultsModalOpen(false)}
              className="mt-4 w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default FinancialPlanner;
