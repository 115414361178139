import React, { useState, useEffect } from 'react';

const HelloWorld = ({ apiUrl }) => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMessage = async () => {
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setMessage(data);
      } catch (e) {
        setError(`Failed to fetch message: ${e.message}`);
        console.error('There was a problem fetching the message:', e);
      } finally {
        setLoading(false);
      }
    };

    fetchMessage();
  }, [apiUrl]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h2>Hello World Test</h2>
      <p>{message}</p>
    </div>
  );
};

export default HelloWorld;