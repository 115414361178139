// src/components/calculators/ResultDisplay.js
import React from 'react';
import { motion } from 'framer-motion';
import { Button } from '../ui/button';

const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(value);
};

const ResultDisplay = ({ result, onDetailsClick }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="mt-6 p-6 bg-gray-100 rounded-lg shadow-md"
    >
      <div className="flex justify-between items-start mb-4">
        <h3 className="text-2xl font-bold text-gray-800">Results</h3>
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <Button
            onClick={onDetailsClick}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
          >
            Details
          </Button>
        </motion.div>
      </div>
      {Object.entries(result).map(([key, value]) => (
        <p key={key} className="text-lg font-semibold text-gray-700 mb-2">
          {key}: <span className="text-gray-900">{typeof value === 'number' ? formatCurrency(value) : value}</span>
        </p>
      ))}
    </motion.div>
  );
};

export default ResultDisplay;