// src/services/api.js
import { useCallback } from 'react';

export const useApi = () => {
  const calculateResults = useCallback(async (data) => {
    // Implement API call to lambda function
  }, []);

  const saveIncomeProfile = useCallback(async (userId, profile) => {
    // Implement API call to save income profile
  }, []);

  const saveAdjustedIncomeProfile = useCallback(async (userId, profile) => {
    // Implement API call to save adjusted income profile
  }, []);

  // Add other API methods as needed

  return {
    calculateResults,
    saveIncomeProfile,
    saveAdjustedIncomeProfile,
  };
};