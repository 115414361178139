// IncomeViewer.js

import React from 'react';

const IncomeViewer = ({ isOpen, onClose, adjustedIncomeProfile }) => {
  if (!isOpen || !adjustedIncomeProfile || !adjustedIncomeProfile.values) return null;

  const incomeValues = adjustedIncomeProfile.values;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-md mx-auto">
        <h2 className="text-2xl font-bold mb-4 text-center">Adjusted Income Overview</h2>
        <div className="overflow-y-auto max-h-80">
          <table className="min-w-full divide-y divide-gray-200 mb-4">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 uppercase tracking-wider">
                  Year
                </th>
                <th className="px-4 py-2 text-right text-sm font-medium text-gray-700 uppercase tracking-wider">
                  Adjusted Income
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {incomeValues.map((income, index) => (
                <tr key={index}>
                  <td className="px-4 py-2 whitespace-nowrap">
                    Year {index + 1}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap text-right">
                    ${Math.round(income).toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default IncomeViewer;
