// IncomeTuning.js
import React, { useEffect, useRef, useCallback } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

const IncomeTuning = ({
  isOpen,
  onClose,
  onSave,
  originalIncomeProfile,
  adjustedIncomeProfile,
  timeHorizon,
  timeBeforeRetirement,
}) => {
  const chartRef = useRef(null);
  const chartDataRef = useRef([]);
  const originalIncomeValuesRef = useRef([]);
  const adjustedIncomeValuesRef = useRef([]);

  const initializeChartData = useCallback(() => {
    if (
      adjustedIncomeProfile &&
      adjustedIncomeProfile.values &&
      adjustedIncomeProfile.values.length === timeHorizon
    ) {
      // Initialize arrays with zeros for the entire time horizon
      originalIncomeValuesRef.current = new Array(timeHorizon).fill(0);
      adjustedIncomeValuesRef.current = adjustedIncomeProfile.values.slice();

      // Fill in the pre-retirement values from original income profile
      if (originalIncomeProfile && originalIncomeProfile.values) {
        for (let i = 0; i < timeBeforeRetirement; i++) {
          originalIncomeValuesRef.current[i] = originalIncomeProfile.values[i] || 0;
        }
      }

      // Create chart data for all years in the time horizon
      return originalIncomeValuesRef.current.map((value, index) => ({
        year: index + 1,
        originalIncome: value,
        adjustedIncome: adjustedIncomeValuesRef.current[index],
      }));
    }
    return [];
  }, [originalIncomeProfile, adjustedIncomeProfile, timeHorizon, timeBeforeRetirement]);

  useEffect(() => {
    if (isOpen) {
      chartDataRef.current = initializeChartData();

      if (chartDataRef.current.length === 0) {
        // Optionally display a message indicating no data
        return;
      }

      const chart = am4core.create('chartdiv', am4charts.XYChart);
      chart.data = chartDataRef.current;
      chart.paddingRight = 20;

      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'year';
      categoryAxis.title.text = 'Year';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = 'Income';
      valueAxis.min = 0;
      valueAxis.strictMinMax = true;
      valueAxis.max =
        Math.max(
          ...originalIncomeValuesRef.current.concat(adjustedIncomeValuesRef.current)
        ) * 1.2;

      function createSeries(field, name, color, draggable) {
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = field;
        series.dataFields.categoryX = 'year';
        series.name = name;
        series.columns.template.tooltipText = '{name}\n[bold]{valueY}[/]';
        series.columns.template.fill = am4core.color(color);
        series.stacked = false;

        if (draggable) {
          series.columns.template.column.cornerRadiusTopLeft = 10;
          series.columns.template.column.cornerRadiusTopRight = 10;

          let bullet = series.bullets.push(new am4charts.CircleBullet());
          bullet.circle.radius = 8;
          bullet.fill = am4core.color('#ffffff');
          bullet.stroke = am4core.color('#000000');
          bullet.strokeWidth = 2;
          bullet.cursorOverStyle = am4core.MouseCursorStyle.verticalResize;
          bullet.draggable = true;

          bullet.events.on('drag', function (event) {
            handleDrag(event);
          });

          bullet.events.on('dragstop', function () {
            chart.validateData();
          });

          function handleDrag(event) {
            let dataItem = event.target.dataItem;
            let value = valueAxis.yToValue(event.target.pixelY);

            // Constrain value between 0 and the maximum value
            value = Math.max(0, Math.min(value, valueAxis.max));

            dataItem.valueY = value;

            // Update adjustedIncomeValuesRef
            adjustedIncomeValuesRef.current[dataItem.index] = value;

            // Update the data in chartDataRef.current
            chartDataRef.current[dataItem.index].adjustedIncome = value;

            // Keep the bullet in the same horizontal position
            event.target.maxX = event.target.minX = event.target.pixelX;
          }
        }
      }

      createSeries('originalIncome', 'Original Income', '#888888', false);
      createSeries('adjustedIncome', 'Adjusted Income', '#67b7dc', true);

      chart.legend = new am4charts.Legend();
      chartRef.current = chart;

      return () => {
        if (chartRef.current) {
          chartRef.current.dispose();
          chartRef.current = null;
        }
      };
    }
  }, [isOpen, initializeChartData]);

  const handleSave = () => {
    const newAdjustedIncomeProfile = {
      values: adjustedIncomeValuesRef.current.map((value) => Math.round(value)),
    };
    onSave(newAdjustedIncomeProfile);
    onClose();
  };

  const handleClose = () => {
    if (chartRef.current) {
      chartRef.current.dispose();
      chartRef.current = null;
    }
    onClose();
  };

  if (!isOpen) return null;

  if (chartDataRef.current.length === 0) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg w-full max-w-md mx-auto">
          <h2 className="text-2xl font-bold mb-4">Tune Income</h2>
          <p>No income data available to display.</p>
          <button
            onClick={handleClose}
            className="mt-4 w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Close
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-4xl mx-auto">
        <h2 className="text-2xl font-bold mb-4">Tune Income</h2>
        <div id="chartdiv" style={{ width: '100%', height: '400px' }}></div>
        <div className="mt-4 flex justify-end">
          <button
            onClick={handleClose}
            className="mr-2 px-4 py-2 bg-gray-300 rounded"
          >
            Close
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default IncomeTuning;
