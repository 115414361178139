// src/components/ui/Breadcrumbs.js
import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

const truncateLabel = (label, maxLength) => {
  return label.length > maxLength ? `${label.substring(0, maxLength)}...` : label;
};

const Breadcrumbs = ({ items }) => {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        {items.map((item, index) => (
          <li key={item.path} className="inline-flex items-center">
            {index > 0 && <ChevronRight className="w-4 h-4 text-gray-400 mx-1" />}
            {index === items.length - 1 ? (
              <span className="text-sm font-medium text-gray-500 md:ml-2">
                <span className="hidden sm:inline">{item.label}</span>
                <span className="inline sm:hidden">{truncateLabel(item.label, 8)}</span>
              </span>
            ) : (
              <Link
                to={item.path}
                className="inline-flex items-center text-sm font-medium text-blue-600 hover:text-blue-800"
              >
                <span className="hidden sm:inline">{item.label}</span>
                <span className="inline sm:hidden">{truncateLabel(item.label, 8)}</span>
              </Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;