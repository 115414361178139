import React from 'react';
import { Modal, ModalContent, ModalHeader, ModalTitle, ModalDescription, ModalFooter } from '../ui/modal';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from '../ui/table';

const formatLabel = (key) => {
  if (key === 'principal') return 'Loan Amount';
  const words = key.split(/(?=[A-Z])/).join(' ');
  return words.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

const formatValue = (key, value) => {
  if (key === 'interestRate' && !isNaN(parseFloat(value))) {
    return parseFloat(value).toFixed(2) + '%';
  }
  if (key === 'loanTerm' && !isNaN(parseFloat(value))) {
    return parseFloat(value).toFixed(2);
  }
  return value;
};

const DetailsModal = ({ isOpen, onClose, data, amortizationSchedule }) => {
  return (
    <Modal open={isOpen} onOpenChange={onClose}>
      <ModalContent className="w-[95vw] max-w-[350px] sm:max-w-[500px] md:max-w-[600px] lg:max-w-[800px] h-[90vh] sm:h-auto overflow-hidden">
        <ModalHeader className="text-sm sm:text-base">
          <ModalTitle>Calculation Details</ModalTitle>
          <ModalDescription>Detailed breakdown of your calculation</ModalDescription>
        </ModalHeader>
        <div className="flex flex-col gap-4 py-4 overflow-y-auto max-h-[calc(90vh-150px)] sm:max-h-[60vh]">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4">
            {Object.entries(data).map(([key, value]) => (
              <div key={key} className="flex flex-col">
                <Label htmlFor={key} className="mb-1 text-xs sm:text-sm">
                  {formatLabel(key)}
                </Label>
                <Input 
                  id={key} 
                  value={formatValue(key, value)} 
                  className="w-full text-xs sm:text-sm p-1 sm:p-2" 
                  readOnly 
                />
              </div>
            ))}
          </div>
          {amortizationSchedule && amortizationSchedule.length > 0 && (
            <div className="mt-4">
              <h3 className="text-sm sm:text-base font-semibold mb-2">Amortization Schedule</h3>
              <div className="overflow-x-auto">
                <Table className="w-full table-fixed">
                  <TableHeader>
                    <TableRow>
                      <TableHead className="text-[10px] sm:text-xs p-1 w-[15%]">Period</TableHead>
                      <TableHead className="text-[10px] sm:text-xs p-1 w-[20%]">Payment</TableHead>
                      <TableHead className="text-[10px] sm:text-xs p-1 w-[20%]">Interest</TableHead>
                      <TableHead className="text-[10px] sm:text-xs p-1 w-[20%]">Principal</TableHead>
                      <TableHead className="text-[10px] sm:text-xs p-1 w-[25%]">Balance</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {amortizationSchedule.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell className="text-[10px] sm:text-xs p-1">{row.period}</TableCell>
                        <TableCell className="text-[10px] sm:text-xs p-1">{row.payment}</TableCell>
                        <TableCell className="text-[10px] sm:text-xs p-1">{row.interest}</TableCell>
                        <TableCell className="text-[10px] sm:text-xs p-1">{row.principal}</TableCell>
                        <TableCell className="text-[10px] sm:text-xs p-1">{row.balance}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          )}
        </div>
        <ModalFooter>
          <Button onClick={onClose} className="text-xs sm:text-sm">Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DetailsModal;