import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Card, CardHeader, CardContent, CardFooter } from '../../../components/ui/card';
import { Button } from '../../../components/ui/button';
import { Calculator, ArrowRight, PiggyBank, Percent, DollarSign } from 'lucide-react';
import Layout from '../../dashboard/Layout';
import Breadcrumbs from '../../../components/ui/Breadcrumbs';

const MotionCard = motion(Card);

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 }
};

const FinancialUtilities = () => {
  const calculators = [
    {
      title: 'Loan Amortization Calculator',
      description: 'Calculate loan payments and view the amortization schedule based on principal, interest rate, and term.',
      icon: <Calculator className="w-8 h-8 text-blue-500" />,
      link: '/loan-amortization',
      color: 'from-blue-400 to-blue-600'
    },
    {
      title: 'Present Value of Annuity Calculator',
      description: 'Calculate the present value of a series of future periodic payments.',
      icon: <DollarSign className="w-8 h-8 text-green-500" />,
      link: '/present-value-annuity',
      color: 'from-green-400 to-green-600'
    },
    {
      title: 'Compound Interest Calculator',
      description: 'See how your investments can grow over time with the power of compound interest.',
      icon: <Percent className="w-8 h-8 text-purple-500" />,
      link: '/compound-interest',
      color: 'from-purple-400 to-purple-600'
    },
    {
      title: 'Retirement Income Calculator',
      description: 'Estimate your retirement income based on your current savings and future contributions.',
      icon: <PiggyBank className="w-8 h-8 text-orange-500" />,
      link: '/retirement-income',
      color: 'from-orange-400 to-orange-600'
    }
  ];

  const breadcrumbItems = [
    { label: 'Home', path: '/' },
    { label: 'Financial Utilities', path: '/financial-utilities' },
  ];

  return (
    <Layout breadcrumbs={<Breadcrumbs items={breadcrumbItems} />}>
      <div className="container mx-auto px-4 py-8 sm:py-12">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-8 sm:mb-12"
        >
          <h1 className="text-3xl sm:text-4xl font-bold mb-4 text-gray-800">Financial Utilities</h1>
          <p className="text-xl text-gray-600">Explore our collection of financial calculators to help you make informed decisions.</p>
        </motion.div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
          {calculators.map((calculator, index) => (
            <MotionCard
              key={calculator.title}
              className="flex flex-col overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300"
              variants={cardVariants}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.3, delay: index * 0.1 }}
            >
              <div className={`h-2 bg-gradient-to-r ${calculator.color}`} />
              <CardHeader className="flex flex-row items-center space-x-4 pb-2">
                {calculator.icon}
                <h3 className="text-2xl font-semibold text-gray-800">{calculator.title}</h3>
              </CardHeader>
              <CardContent className="flex-grow">
                <p className="text-gray-600">{calculator.description}</p>
              </CardContent>
              <CardFooter className="mt-auto">
                <Button
                  asChild
                  variant="default"
                  className={`w-full bg-gradient-to-r ${calculator.color} hover:opacity-90`}
                >
                  <Link to={calculator.link} className="inline-flex items-center justify-center">
                    Open Calculator <ArrowRight className="ml-2 h-4 w-4" />
                  </Link>
                </Button>
              </CardFooter>
            </MotionCard>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default FinancialUtilities;