// src/components/calculators/MathFormulaDisplay.js
import React from 'react';
import { BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';

const MathFormulaDisplay = ({ formula, description }) => {
  return (
    <div className="mt-4">
      <h4 className="text-lg font-semibold mb-2">{description}</h4>
      <BlockMath math={formula} />
    </div>
  );
};

export default MathFormulaDisplay;