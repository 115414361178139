// src/components/ui/AdSpace.js
import React from 'react';
import { Card, CardContent } from './card';

const AdSpace = ({ width, height, adContent }) => (
  <Card className={`w-${width} h-${height}`}>
    <CardContent className="flex items-center justify-center h-full">
      {adContent || <p className="text-gray-400">Ad Space</p>}
    </CardContent>
  </Card>
);

export default AdSpace;