import React, { useState, useCallback, useEffect } from 'react';
import { Stage, Layer, Line as KonvaLine, Circle, Text, Rect } from 'react-konva';

const formatCurrency = (value) => {
  if (value >= 1000000) {
    return `$${(value / 1000000).toFixed(1)}M`;
  } else if (value >= 1000) {
    return `$${(value / 1000).toFixed(1)}k`;
  } else {
    return `$${value.toFixed(2)}`;
  }
};

const IncomeProfileEditor = ({
  isOpen,
  onClose,
  onSave,
  initialIncomeProfile,
  timeHorizon,
  upperIncomeLimit,
}) => {
  const [points, setPoints] = useState([]);
  const [stageSize, setStageSize] = useState({ width: 800, height: 600 });
  const [hoveredPoint, setHoveredPoint] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      const width = Math.min(800, window.innerWidth - 40);
      const height = Math.min(600, window.innerHeight - 200);
      setStageSize({ width, height });
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const initializePoints = useCallback(() => {
    const padding = 20;
    const labelWidth = 60;
    const graphWidth = stageSize.width - 2 * padding - labelWidth;
    const graphHeight = stageSize.height - 2 * padding;

    if (initialIncomeProfile && initialIncomeProfile.controlPoints) {
      return initialIncomeProfile.controlPoints.map((point) => ({
        x: (point.x / 100) * graphWidth + padding + labelWidth,
        y:
          stageSize.height -
          ((point.y / upperIncomeLimit) * graphHeight + padding),
      }));
    } else {
      const startX = padding + labelWidth;
      const endX = padding + labelWidth + graphWidth;
      let controlPointsX;

      if (timeHorizon === 1) {
        const midX = (startX + endX) / 2;
        controlPointsX = [midX, midX, midX, midX];
      } else if (timeHorizon === 2) {
        controlPointsX = [startX, (startX + endX) / 2, (startX + endX) / 2, endX];
      } else {
        controlPointsX = [
          startX,
          startX + graphWidth * 0.33,
          startX + graphWidth * 0.66,
          endX,
        ];
      }

      const defaultY =
        stageSize.height - ((50000 / upperIncomeLimit) * graphHeight + padding);

      return controlPointsX.map((x) => ({
        x,
        y: defaultY,
      }));
    }
  }, [stageSize, upperIncomeLimit, initialIncomeProfile, timeHorizon]);

  useEffect(() => {
    setPoints(initializePoints());
  }, [initializePoints, timeHorizon]);

  const handleDragMove = (index, e) => {
    const newPoints = [...points];
    const padding = 20;
    const labelWidth = 60;
    const graphWidth = stageSize.width - 2 * padding - labelWidth;
    const graphHeight = stageSize.height - 2 * padding;

    let newX = e.target.x();
    let newY = e.target.y();

    newY = Math.max(padding, Math.min(stageSize.height - padding, newY));

    if (index === 1 || index === 2) {
      const minX = padding + labelWidth + graphWidth * 0.02;
      const maxX = padding + labelWidth + graphWidth * 0.98;
      newX = Math.max(minX, Math.min(maxX, newX));
    } else {
      newX = points[index].x;
    }

    newPoints[index] = { x: newX, y: newY };
    setPoints(newPoints);
  };

  const handleSave = () => {
    const padding = 20;
    const labelWidth = 60;
    const graphWidth = stageSize.width - 2 * padding - labelWidth;
    const graphHeight = stageSize.height - 2 * padding;

    const normalizedData = [];

    if (timeHorizon >= 1) {
      const denominator = timeHorizon > 1 ? timeHorizon - 1 : 1;
      for (let i = 1; i <= timeHorizon; i++) {
        const x =
          ((i - 1) / denominator) * graphWidth + padding + labelWidth;
        const y = getYForX(x);
        const value = Math.round(
          ((stageSize.height - padding - y) / graphHeight) * upperIncomeLimit
        );
        normalizedData.push(value);
      }
    }

    const controlPoints = points.map((point) => ({
      x: ((point.x - padding - labelWidth) / graphWidth) * 100,
      y:
        ((stageSize.height - padding - point.y) / graphHeight) *
        upperIncomeLimit,
    }));

    onSave({ values: normalizedData, controlPoints });
  };

  const getYForX = useCallback(
    (x) => {
      const bezier = (t, p0, p1, p2, p3) => {
        const mt = 1 - t;
        return (
          mt * mt * mt * p0 +
          3 * mt * mt * t * p1 +
          3 * mt * t * t * p2 +
          t * t * t * p3
        );
      };

      const epsilon = 0.1;
      let t = 0.5;
      let tMin = 0;
      let tMax = 1;
      let xEst;

      const p0x = points[0].x;
      const p1x = points[1].x;
      const p2x = points[2].x;
      const p3x = points[3].x;

      for (let i = 0; i < 20; i++) {
        xEst = bezier(t, p0x, p1x, p2x, p3x);
        if (Math.abs(xEst - x) < epsilon) {
          break;
        }
        if (xEst < x) {
          tMin = t;
        } else {
          tMax = t;
        }
        t = (tMin + tMax) / 2;
      }

      const p0y = points[0].y;
      const p1y = points[1].y;
      const p2y = points[2].y;
      const p3y = points[3].y;
      const y = bezier(t, p0y, p1y, p2y, p3y);

      return y;
    },
    [points]
  );

  const renderCurve = () => {
    const curvePoints = [];
    const steps = 200;
    for (let i = 0; i <= steps; i++) {
      const t = i / steps;
      const x =
        Math.pow(1 - t, 3) * points[0].x +
        3 * Math.pow(1 - t, 2) * t * points[1].x +
        3 * (1 - t) * t * t * points[2].x +
        t * t * t * points[3].x;
      const y =
        Math.pow(1 - t, 3) * points[0].y +
        3 * Math.pow(1 - t, 2) * t * points[1].y +
        3 * (1 - t) * t * t * points[2].y +
        t * t * t * points[3].y;
      curvePoints.push({ x, y });
    }

    return (
      <KonvaLine
        points={curvePoints.flatMap((p) => [p.x, p.y])}
        stroke="blue"
        strokeWidth={2}
      />
    );
  };

  const renderGrid = () => {
    const padding = 20;
    const labelWidth = 60;
    const gridLines = [];
    const step = (stageSize.height - 2 * padding) / 4;
    for (let i = 1; i < 4; i++) {
      gridLines.push(
        <KonvaLine
          key={`h${i}`}
          points={[
            padding + labelWidth,
            i * step + padding,
            stageSize.width - padding,
            i * step + padding,
          ]}
          stroke="rgba(0,0,0,0.1)"
          strokeWidth={1}
        />
      );
    }
    const graphWidth = stageSize.width - 2 * padding - labelWidth;

    if (timeHorizon >= 1) {
      const yearStep = timeHorizon > 30 ? 2 : 1;
      const denominator = timeHorizon > 1 ? timeHorizon - 1 : 1;
      for (let i = 1; i <= timeHorizon; i += yearStep) {
        const x =
          ((i - 1) / denominator) * graphWidth + padding + labelWidth;
        gridLines.push(
          <KonvaLine
            key={`v${i}`}
            points={[x, padding, x, stageSize.height - padding]}
            stroke="rgba(0,0,0,0.1)"
            strokeWidth={1}
          />
        );
      }
    }
    return gridLines;
  };

  const renderLabels = () => {
    const padding = 20;
    const labelWidth = 60;
    const graphWidth = stageSize.width - 2 * padding - labelWidth;
    const labels = [];

    if (timeHorizon >= 1) {
      const yearStep = timeHorizon > 30 ? 2 : 1;
      const denominator = timeHorizon > 1 ? timeHorizon - 1 : 1;
      for (let i = 1; i <= timeHorizon; i += yearStep) {
        const x =
          ((i - 1) / denominator) * graphWidth + padding + labelWidth;
        labels.push(
          <Text
            key={`label${i}`}
            x={x - 10}
            y={stageSize.height - padding + 5}
            text={`${i}`}
            fontSize={10}
            fill="black"
            align="center"
            width={20}
          />
        );
      }
    }
    return labels;
  };

  const renderValueLabels = () => {
    const padding = 20;
    return Array(5)
      .fill()
      .map((_, i) => (
        <Text
          key={`valueLabel${i}`}
          x={5}
          y={i * ((stageSize.height - 2 * padding) / 4) + padding - 6}
          text={formatCurrency(upperIncomeLimit * (1 - i / 4))}
          fontSize={12}
          fill="black"
          align="right"
          width={55}
        />
      ));
  };

  const renderIntersectionPoints = () => {
    const padding = 20;
    const labelWidth = 60;
    const graphWidth = stageSize.width - 2 * padding - labelWidth;
    const pointsArray = [];

    if (timeHorizon >= 1) {
      const denominator = timeHorizon > 1 ? timeHorizon - 1 : 1;
      for (let i = 1; i <= timeHorizon; i++) {
        const x =
          ((i - 1) / denominator) * graphWidth + padding + labelWidth;
        const y = getYForX(x);
        pointsArray.push(
          <Circle
            key={`intersection${i}`}
            x={x}
            y={y}
            radius={3}
            fill="green"
            onMouseEnter={() => setHoveredPoint({ x, y, index: i })}
            onMouseLeave={() => setHoveredPoint(null)}
          />
        );
      }
    }
    return pointsArray;
  };

  const renderTooltip = () => {
    if (!hoveredPoint) return null;
    const padding = 20;
    const graphHeight = stageSize.height - 2 * padding;
    const value = Math.round(
      ((stageSize.height - padding - hoveredPoint.y) / graphHeight) *
        upperIncomeLimit
    );
    const tooltipWidth = 60;
    const tooltipHeight = 20;

    let tooltipX = hoveredPoint.x + 10;
    let tooltipY = hoveredPoint.y - tooltipHeight - 10;

    if (tooltipX + tooltipWidth > stageSize.width) {
      tooltipX = hoveredPoint.x - tooltipWidth - 10;
    }

    if (tooltipY < 0) {
      tooltipY = hoveredPoint.y + 10;
    }

    return (
      <React.Fragment>
        <Rect
          x={tooltipX}
          y={tooltipY}
          width={tooltipWidth}
          height={tooltipHeight}
          fill="white"
          stroke="black"
          strokeWidth={1}
        />
        <Text
          x={tooltipX + 5}
          y={tooltipY + 5}
          text={formatCurrency(value)}
          fontSize={12}
          fill="black"
          width={tooltipWidth - 10}
        />
      </React.Fragment>
    );
  };

  const renderDynamicLabels = () => {
    const padding = 20;
    const labelWidth = 60;
    const graphWidth = stageSize.width - 2 * padding - labelWidth;
    const graphHeight = stageSize.height - 2 * padding;

    const startX = padding + labelWidth;
    const endX = padding + labelWidth + graphWidth;
    const startY = getYForX(startX);
    const endY = getYForX(endX);

    const startValue = Math.round(
      ((stageSize.height - padding - startY) / graphHeight) * upperIncomeLimit
    );
    const endValue = Math.round(
      ((stageSize.height - padding - endY) / graphHeight) * upperIncomeLimit
    );

    return (
      <>
        <Text
          x={startX - 30}
          y={startY - 20}
          text={formatCurrency(startValue)}
          fontSize={12}
          fill="black"
          align="right"
          width={60}
        />
        <Text
          x={endX - 70}
          y={endY - 20}
          text={formatCurrency(endValue)}
          fontSize={12}
          fill="black"
          align="right"
          width={60}
        />
      </>
    );
  };

  if (!isOpen) return null;

  if (timeHorizon === 0) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg max-w-4xl mx-auto">
          <h2 className="text-2xl font-bold mb-4">Edit Income Profile</h2>
          <p>The person is already retired.</p>
          <div className="mt-4 flex justify-end">
            <button
              onClick={onClose}
              className="mr-2 px-4 py-2 bg-gray-300 rounded"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-4xl mx-auto">
        <h2 className="text-2xl font-bold mb-4">Edit Income Profile</h2>
        <Stage width={stageSize.width} height={stageSize.height + 20}>
          <Layer>
            {renderGrid()}
            {renderValueLabels()}
            {renderCurve()}
            {renderIntersectionPoints()}
            {renderDynamicLabels()}
            {points.map((point, index) => (
              <Circle
                key={index}
                x={point.x}
                y={point.y}
                radius={8}
                fill="red"
                draggable
                dragBoundFunc={(pos) => {
                  let newX = pos.x;
                  let newY = pos.y;
                  const padding = 20;
                  const labelWidth = 60;
                  const graphWidth =
                    stageSize.width - 2 * padding - labelWidth;
                  const graphHeight = stageSize.height - 2 * padding;

                  newY = Math.max(
                    padding,
                    Math.min(stageSize.height - padding, newY)
                  );

                  if (index === 1 || index === 2) {
                    const minX =
                      padding + labelWidth + graphWidth * 0.02;
                    const maxX =
                      padding + labelWidth + graphWidth * 0.98;
                    newX = Math.max(
                      minX,
                      Math.min(maxX, newX)
                    );
                  } else {
                    newX = points[index].x;
                  }
                  return { x: newX, y: newY };
                }}
                onDragMove={(e) => handleDragMove(index, e)}
              />
            ))}
            {renderTooltip()}
            {renderLabels()}
          </Layer>
        </Stage>
        <div className="mt-4 flex justify-end">
          <button
            onClick={onClose}
            className="mr-2 px-4 py-2 bg-gray-300 rounded"
          >
            Close
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default IncomeProfileEditor;
