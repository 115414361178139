// src/components/calculators/CalculatorForm.js
import React from 'react';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Button } from '../ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';

const formatCurrency = (value) => {
  if (!value) return '';
  const number = parseFloat(value.replace(/[^\d.-]/g, ''));
  if (isNaN(number)) return '';
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(number);
};

const CalculatorForm = ({ inputs, onSubmit, formState, setFormState }) => {
  const handleInputChange = (name, value, type) => {
    let processedValue = value;

    if (type === 'number') {
      // Allow decimal input, but prevent multiple decimal points
      processedValue = value.replace(/[^\d.]/g, '');
      const parts = processedValue.split('.');
      if (parts.length > 2) {
        processedValue = parts[0] + '.' + parts.slice(1).join('');
      }
    } else if (name === 'principal') {
      processedValue = formatCurrency(value);
    }

    setFormState(prev => ({ ...prev, [name]: processedValue }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const submissionData = { ...formState };
    if (submissionData.principal) {
      submissionData.principal = parseFloat(submissionData.principal.replace(/[^\d.-]/g, ''));
    }
    // Parse other numeric fields
    ['interestRate', 'loanTerm'].forEach(field => {
      if (submissionData[field]) {
        submissionData[field] = parseFloat(submissionData[field]);
      }
    });
    onSubmit(submissionData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {inputs.map((input) => (
        <div key={input.name}>
          <Label htmlFor={input.name}>{input.label}</Label>
          {input.type === 'select' ? (
            <Select 
              onValueChange={(value) => handleInputChange(input.name, value, input.type)} 
              value={formState[input.name]}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder={input.placeholder} />
              </SelectTrigger>
              <SelectContent>
                {input.options.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          ) : (
            <Input
              id={input.name}
              type="text"
              inputMode={input.type === 'number' ? 'decimal' : 'text'}
              value={formState[input.name]}
              onChange={(e) => handleInputChange(input.name, e.target.value, input.type)}
              placeholder={input.placeholder}
              required={input.required}
              onBlur={() => {
                if (input.type === 'number' && formState[input.name]) {
                  const formatted = parseFloat(formState[input.name]).toFixed(2);
                  setFormState(prev => ({ ...prev, [input.name]: formatted }));
                }
              }}
            />
          )}
        </div>
      ))}
      <Button type="submit" className="w-full bg-gradient-to-r from-blue-400 to-blue-600 hover:opacity-90">
        Calculate
      </Button>
    </form>
  );
};

export default CalculatorForm;