import React from 'react';

const Logo = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 500" className={className}>
    <defs>
      <style>{`.cls-1{fill:#000;}.cls-2{isolation:isolate;}.cls-3{fill:none;stroke:#000;}.cls-4{fill:#FFF;}.cls-5{fill:#FF0000;}`}</style>
    </defs>
    <g id="Retire">
      <path className="cls-1" d="M160.01807,279.18848H107.40186V378.085H41.356V107.92871H160.4585q56.79932,0,87.62109,21.33789,30.81959,21.33912,30.82129,60.30274,0,27.64965-14.19971,46.10839-14.20019,18.46509-43.04,29.40918L291.00928,375.4873V378.085H220.12012Zm-52.61621-45.08789h53.27685q24.87525,0,38.52686-10.669,13.64795-10.66773,13.64941-29.40918,0-19.10815-12.87891-30.05859-12.87889-10.94605-39.51757-10.94727H107.40186Z"/>
      <path className="cls-1" d="M407.63184,381.7959q-52.3982,0-85.30957-27.08985-32.91432-27.08788-32.9126-72.17773V277.333q0-30.2417,13.86963-54.08692a93.06916,93.06916,0,0,1,39.29736-36.73828q25.42749-12.89429,58.01025-12.89551,48.87378,0,76.94336,25.97657Q505.60034,225.5686,505.6001,273.251v21.89453H353.91455a47.51967,47.51967,0,0,0,18.603,31.543q15.52076,11.877,39.29736,11.875,36.76392,0,57.46-22.45118l31.26172,29.502q-14.31372,17.07274-38.74707,26.626Q437.3523,381.794,407.63184,381.7959ZM400.3667,216.84473q-18.93676,0-30.71143,10.811-11.77953,10.81128-15.08056,30.937h88.502v-4.28808q-.44385-17.89014-11.44824-27.6753Q420.61719,216.845,400.3667,216.84473Z"/>
      <path className="cls-1" d="M599.52783,127.96777v49.35547h40.72852v39.33594H599.52783V316.85449q0,11.13282,5.06348,15.957,5.06176,4.82667,19.37353,4.82422a119.78949,119.78949,0,0,0,18.71289-1.29883v40.63477a153.76945,153.76945,0,0,1-38.52636,4.82422q-66.92871,0-68.24756-56.96289V216.65918H501.11963V177.32324h34.78418V127.96777Z"/>
      <path className="cls-1" d="M652.40088,125.37012a24.49214,24.49214,0,0,1,9.57666-19.85352q9.57714-7.793,26.08838-7.793,16.28905,0,25.978,7.793,9.68481,7.793,9.68652,19.85352a24.475,24.475,0,0,1-9.79688,20.03906q-9.79833,7.793-25.86767,7.793-16.073,0-25.86817-7.793A24.467,24.467,0,0,1,652.40088,125.37012ZM719.98779,378.085H656.14355V177.32324h63.84424Z"/>
      <path className="cls-1" d="M885.00146,227.60645a204.747,204.747,0,0,0-22.896-1.48438q-36.1084,0-47.333,20.5957V378.085h-63.624V177.32324H811.25l1.76123,23.93555q19.15356-27.64527,53.05713-27.64649a78.362,78.362,0,0,1,19.814,2.41211Z"/>
      <path className="cls-1" d="M1003.64258,381.7959q-52.39893,0-85.31006-27.08985-32.91431-27.08788-32.9126-72.17773V277.333q0-30.2417,13.86963-54.08692a93.06916,93.06916,0,0,1,39.29736-36.73828q25.42749-12.89429,58.01026-12.89551,48.87378,0,76.94385,25.97657,28.06933,25.97973,28.06836,73.66211v21.89453H949.9248a47.51971,47.51971,0,0,0,18.603,31.543q15.52076,11.877,39.29639,11.875,36.76464,0,57.46094-22.45118l31.26172,29.502q-14.31446,17.07274-38.74805,26.626Q1033.3623,381.794,1003.64258,381.7959ZM996.377,216.84473q-18.93677,0-30.71142,10.811Q953.886,238.467,950.585,258.59277h88.501v-4.28808q-.44238-17.89014-11.44727-27.6753Q1016.62891,216.845,996.377,216.84473Z"/>
    </g>
    <g id="Rectangle_1" data-name="Rectangle 1" className="cls-2">
      <g id="Rectangle_1-2" data-name="Rectangle 1">
        <rect className="cls-5" x="1115.00275" y="75" width="874.99994" height="350"/>
        <rect className="cls-3" x="1115.00275" y="75" width="874.99994" height="350"/>
      </g>
    </g>
    <g id="Math">
      <path className="cls-4" d="M1226.35449,114.76758l65.74219,185.625,65.39062-185.625h69.25782v255.9375H1373.835V300.74414l5.27344-120.76172-69.082,190.72266h-36.21094L1204.90918,180.1582l5.27344,120.58594v69.96094h-52.73438V114.76758Z"/>
      <path className="cls-4" d="M1579.6748,370.70508a56.34984,56.34984,0,0,1-5.09765-17.05078q-18.457,20.5664-47.98828,20.5664-27.94923,0-46.31836-16.17187-18.37061-16.17042-18.36914-40.78125,0-30.23145,22.41211-46.40625,22.4121-16.17042,64.77539-16.34766h23.3789V243.61523q0-13.18359-6.76757-21.09375-6.76905-7.91014-21.35743-7.91015-12.83349,0-20.127,6.15234-7.29639,6.15528-7.29492,16.875h-50.80078a51.03192,51.03192,0,0,1,10.19531-30.58594q10.19238-14.061,28.82812-22.06054,18.6299-7.99659,41.83594-7.99805,35.15479,0,55.81055,17.666,20.65282,17.666,20.6543,49.6582v82.44141q.17284,27.07178,7.55859,40.957v2.98828Zm-42.01171-35.332a43.75851,43.75851,0,0,0,20.74218-5.00977,32.91509,32.91509,0,0,0,14.0625-13.44726V284.2207H1553.4834q-38.146,0-40.60547,26.36719l-.17578,2.98828a20.32936,20.32936,0,0,0,6.67969,15.64453Q1526.06005,335.376,1537.66309,335.37305Z"/>
      <path className="cls-4" d="M1723.28809,133.752v46.75782h32.51953v37.26562h-32.51953v94.92188q0,10.54688,4.043,15.11718,4.04151,4.57324,15.46875,4.57032a80.93562,80.93562,0,0,0,14.94141-1.23047v38.49609a104.61731,104.61731,0,0,1-30.76172,4.57031q-53.439,0-54.49219-53.96484V217.77539h-27.77343V180.50977h27.77343V133.752Z"/>
      <path className="cls-4" d="M1833.67871,201.252q20.21192-24.2578,50.80078-24.25781,61.87208,0,62.75391,71.89453V370.70508h-50.80078V250.29492q0-16.34766-7.03125-24.16992-7.03272-7.81933-23.37891-7.82227-22.32568,0-32.34375,17.22657V370.70508h-50.80078v-270h50.80078Z"/>
    </g>
  </svg>
);

export default Logo;