import React, { useState, useEffect, useCallback } from 'react';
import { Stage, Layer, Line as KonvaLine, Circle, Text, Rect } from 'react-konva';

const formatCurrency = (value) => {
  if (value >= 1000000) {
    return `$${(value / 1000000).toFixed(1)}M`;
  } else if (value >= 1000) {
    return `$${(value / 1000).toFixed(1)}k`;
  } else {
    return `$${value.toFixed(2)}`;
  }
};

const ProfileEditorModal = ({
  isOpen,
  onClose,
  initialValue,
  maxValue,
  timeHorizon,
  onSave,
  savedProfile,
}) => {
  const [points, setPoints] = useState([]);
  const [stageSize, setStageSize] = useState({ width: 800, height: 600 });
  const [hoveredPoint, setHoveredPoint] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      const width = Math.min(800, window.innerWidth - 40);
      const height = Math.min(600, window.innerHeight - 200);
      setStageSize({ width, height });
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const initializePoints = useCallback(() => {
    const padding = 20;
    const labelWidth = 60;
    const graphWidth = stageSize.width - 2 * padding - labelWidth;
    const graphHeight = stageSize.height - 2 * padding;

    if (savedProfile && savedProfile.controlPoints) {
      return savedProfile.controlPoints.map((point) => ({
        x: (point.x / 100) * graphWidth + padding + labelWidth,
        y: stageSize.height - ((point.y / maxValue) * graphHeight + padding),
      }));
    } else {
      return [
        {
          x: padding + labelWidth,
          y:
            stageSize.height -
            ((initialValue / maxValue) * graphHeight + padding),
        },
        {
          x: labelWidth + padding + graphWidth * 0.25,
          y:
            stageSize.height -
            ((initialValue / maxValue) * graphHeight + padding),
        },
        {
          x: labelWidth + padding + graphWidth * 0.75,
          y:
            stageSize.height -
            ((initialValue / maxValue) * graphHeight + padding),
        },
        {
          x: labelWidth + graphWidth + padding,
          y:
            stageSize.height -
            ((initialValue / maxValue) * graphHeight + padding),
        },
      ];
    }
  }, [stageSize, initialValue, maxValue, savedProfile]);

  useEffect(() => {
    setPoints(initializePoints());
  }, [initializePoints]);

  const handleDragMove = (index, e) => {
    const newPoints = [...points];
    const padding = 20;
    const labelWidth = 60;
    const graphWidth = stageSize.width - 2 * padding - labelWidth;
    const graphHeight = stageSize.height - 2 * padding;

    let newX = e.target.x();
    let newY = e.target.y();

    // Limit vertical movement
    newY = Math.max(padding, Math.min(stageSize.height - padding, newY));

    // For inner points (indices 1 and 2), limit horizontal movement
    if (index === 1 || index === 2) {
      const minX = padding + labelWidth + graphWidth * 0.1;
      const maxX = padding + labelWidth + graphWidth * 0.9;
      newX = Math.max(minX, Math.min(maxX, newX));
    } else {
      // For first and last points, keep x fixed
      newX = points[index].x;
    }

    newPoints[index] = { x: newX, y: newY };
    setPoints(newPoints);
  };

  const handleSave = () => {
    const padding = 20;
    const labelWidth = 60;
    const graphWidth = stageSize.width - 2 * padding - labelWidth;
    const graphHeight = stageSize.height - 2 * padding;

    const normalizedData = Array(timeHorizon + 1)
      .fill()
      .map((_, i) => {
        const x = (i / timeHorizon) * graphWidth + padding + labelWidth;
        const y = getYForX(x);
        return Math.round(
          ((stageSize.height - padding - y) / graphHeight) * maxValue
        );
      });

    const controlPoints = points.map((point) => ({
      x: ((point.x - padding - labelWidth) / graphWidth) * 100,
      y: ((stageSize.height - padding - point.y) / graphHeight) * maxValue,
    }));

    onSave({ values: normalizedData, controlPoints });
  };

  const renderGrid = () => {
    const padding = 20;
    const labelWidth = 60;
    const gridLines = [];
    const step = (stageSize.height - 2 * padding) / 4;
    for (let i = 1; i < 4; i++) {
      gridLines.push(
        <KonvaLine
          key={`h${i}`}
          points={[
            padding + labelWidth,
            i * step + padding,
            stageSize.width - padding,
            i * step + padding,
          ]}
          stroke="rgba(0,0,0,0.1)"
          strokeWidth={1}
        />
      );
    }
    const yearStep = timeHorizon > 30 ? 2 : 1;
    const graphWidth = stageSize.width - 2 * padding - labelWidth;
    for (let i = 0; i <= timeHorizon; i += yearStep) {
      const x = (i / timeHorizon) * graphWidth + padding + labelWidth;
      gridLines.push(
        <KonvaLine
          key={`v${i}`}
          points={[x, padding, x, stageSize.height - padding]}
          stroke="rgba(0,0,0,0.1)"
          strokeWidth={1}
        />
      );
    }
    return gridLines;
  };

  const renderLabels = () => {
    const padding = 20;
    const labelWidth = 60;
    const graphWidth = stageSize.width - 2 * padding - labelWidth;
    const yearStep = timeHorizon > 30 ? 2 : 1;
    const labels = [];
    for (let i = 0; i <= timeHorizon; i += yearStep) {
      const x = (i / timeHorizon) * graphWidth + padding + labelWidth;
      labels.push(
        <Text
          key={`label${i}`}
          x={x - 10}
          y={stageSize.height - padding + 5}
          text={`${i}`}
          fontSize={10}
          fill="black"
          align="center"
          width={20}
        />
      );
    }
    return labels;
  };

  const renderValueLabels = () => {
    const padding = 20;
    return Array(5)
      .fill()
      .map((_, i) => (
        <Text
          key={`valueLabel${i}`}
          x={5}
          y={i * ((stageSize.height - 2 * padding) / 4) + padding - 6}
          text={formatCurrency(maxValue * (1 - i / 4))}
          fontSize={12}
          fill="black"
          align="right"
          width={55}
        />
      ));
  };

  const getYForX = useCallback(
    (x) => {
      // Parametric cubic Bézier curve functions
      const bezier = (t, p0, p1, p2, p3) => {
        const mt = 1 - t;
        return (
          mt * mt * mt * p0 +
          3 * mt * mt * t * p1 +
          3 * mt * t * t * p2 +
          t * t * t * p3
        );
      };

      // Since x(t) is not easily invertible, we need to find t for a given x numerically
      const epsilon = 0.1; // Tolerance for the binary search
      let t = 0.5; // Initial guess
      let tMin = 0;
      let tMax = 1;
      let xEst;

      const p0x = points[0].x;
      const p1x = points[1].x;
      const p2x = points[2].x;
      const p3x = points[3].x;

      // Use binary search to find t such that x(t) ≈ x
      for (let i = 0; i < 20; i++) {
        xEst = bezier(t, p0x, p1x, p2x, p3x);
        if (Math.abs(xEst - x) < epsilon) {
          break;
        }
        if (xEst < x) {
          tMin = t;
        } else {
          tMax = t;
        }
        t = (tMin + tMax) / 2;
      }

      // Now compute y(t)
      const p0y = points[0].y;
      const p1y = points[1].y;
      const p2y = points[2].y;
      const p3y = points[3].y;
      const y = bezier(t, p0y, p1y, p2y, p3y);

      return y;
    },
    [points]
  );

  const renderCurve = () => {
    const curvePoints = [];
    const steps = 200; // Increase for smoother curve
    for (let i = 0; i <= steps; i++) {
      const t = i / steps;
      const x =
        Math.pow(1 - t, 3) * points[0].x +
        3 * Math.pow(1 - t, 2) * t * points[1].x +
        3 * (1 - t) * t * t * points[2].x +
        t * t * t * points[3].x;
      const y =
        Math.pow(1 - t, 3) * points[0].y +
        3 * Math.pow(1 - t, 2) * t * points[1].y +
        3 * (1 - t) * t * t * points[2].y +
        t * t * t * points[3].y;
      curvePoints.push({ x, y });
    }

    return (
      <KonvaLine
        points={curvePoints.flatMap((p) => [p.x, p.y])}
        stroke="blue"
        strokeWidth={2}
      />
    );
  };

  const renderIntersectionPoints = () => {
    const padding = 20;
    const labelWidth = 60;
    const graphWidth = stageSize.width - 2 * padding - labelWidth;
    return Array(timeHorizon + 1)
      .fill()
      .map((_, i) => {
        const x = (i / timeHorizon) * graphWidth + padding + labelWidth;
        const y = getYForX(x);
        return (
          <Circle
            key={`intersection${i}`}
            x={x}
            y={y}
            radius={3}
            fill="green"
            onMouseEnter={() => setHoveredPoint({ x, y, index: i })}
            onMouseLeave={() => setHoveredPoint(null)}
          />
        );
      });
  };

  const renderTooltip = () => {
    if (!hoveredPoint) return null;
    const padding = 20;
    const graphHeight = stageSize.height - 2 * padding;
    const value = Math.round(
      ((stageSize.height - padding - hoveredPoint.y) / graphHeight) * maxValue
    );
    const tooltipWidth = 80;
    const tooltipHeight = 30;

    let tooltipX = hoveredPoint.x + 10;
    let tooltipY = hoveredPoint.y - tooltipHeight - 10;

    if (tooltipX + tooltipWidth > stageSize.width) {
      tooltipX = hoveredPoint.x - tooltipWidth - 10;
    }

    if (tooltipY < 0) {
      tooltipY = hoveredPoint.y + 10;
    }

    return (
      <React.Fragment>
        <Rect
          x={tooltipX}
          y={tooltipY}
          width={tooltipWidth}
          height={tooltipHeight}
          fill="white"
          stroke="black"
          strokeWidth={1}
        />
        <Text
          x={tooltipX + 5}
          y={tooltipY + 5}
          text={formatCurrency(value)}
          fontSize={12}
          fill="black"
          width={tooltipWidth - 10}
        />
      </React.Fragment>
    );
  };

  const renderPermanentLabels = () => {
    const padding = 20;
    const labelWidth = 60;
    const graphWidth = stageSize.width - 2 * padding - labelWidth;
    const graphHeight = stageSize.height - 2 * padding;

    const firstX = points[0].x;
    const lastX = points[3].x;

    const firstValue = Math.round(
      ((stageSize.height - padding - getYForX(firstX)) / graphHeight) * maxValue
    );
    const lastValue = Math.round(
      ((stageSize.height - padding - getYForX(lastX)) / graphHeight) * maxValue
    );

    const labelHeight = 20;

    const adjustLabelPosition = (x, y, isFirst) => {
      const graphY = getYForX(x);
      if (Math.abs(y - graphY) < labelHeight) {
        return isFirst ? y - labelHeight - 5 : y + labelHeight + 5;
      }
      return y;
    };

    const firstLabelY = adjustLabelPosition(
      firstX,
      getYForX(firstX) - labelHeight - 5,
      true
    );
    const lastLabelY = adjustLabelPosition(
      lastX,
      getYForX(lastX) - labelHeight - 5,
      false
    );

    return (
      <React.Fragment>
        <Text
          x={firstX - labelWidth / 2}
          y={firstLabelY}
          text={formatCurrency(firstValue)}
          fontSize={12}
          fill="black"
          width={labelWidth}
          align="center"
        />
        <Text
          x={lastX - labelWidth / 2}
          y={lastLabelY}
          text={formatCurrency(lastValue)}
          fontSize={12}
          fill="black"
          width={labelWidth}
          align="center"
        />
      </React.Fragment>
    );
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-4xl mx-auto">
        <h2 className="text-2xl font-bold mb-4">Edit Income Profile</h2>
        <Stage width={stageSize.width} height={stageSize.height + 20}>
          <Layer>
            {renderGrid()}
            {renderValueLabels()}
            {renderCurve()}
            {renderIntersectionPoints()}
            {points.map((point, index) => (
              <Circle
                key={index}
                x={point.x}
                y={point.y}
                radius={8}
                fill="red"
                draggable
                dragBoundFunc={(pos) => {
                  let newX = pos.x;
                  let newY = pos.y;
                  const padding = 20;
                  const labelWidth = 60;
                  const graphWidth = stageSize.width - 2 * padding - labelWidth;
                  const graphHeight = stageSize.height - 2 * padding;

                  // Limit vertical movement
                  newY = Math.max(
                    padding,
                    Math.min(stageSize.height - padding, newY)
                  );

                  // For inner points (indices 1 and 2), limit horizontal movement
                  if (index === 1 || index === 2) {
                    const minX =
                      padding + labelWidth + graphWidth * 0.02;
                    const maxX =
                      padding + labelWidth + graphWidth * 0.98;
                    newX = Math.max(minX, Math.min(maxX, newX));
                  } else {
                    // For first and last points, keep x fixed
                    newX = points[index].x;
                  }
                  return { x: newX, y: newY };
                }}
                onDragMove={(e) => handleDragMove(index, e)}
              />
            ))}
            {renderTooltip()}
            {renderLabels()}
            {renderPermanentLabels()}
          </Layer>
        </Stage>
        <div className="mt-4 flex justify-end">
          <button
            onClick={onClose}
            className="mr-2 px-4 py-2 bg-gray-300 rounded"
          >
            Close
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

const IncomeProfileEditor = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [incomeProfile, setIncomeProfile] = useState(null);
  const [timeHorizon, setTimeHorizon] = useState(20);
  const [upperIncomeLimit, setUpperIncomeLimit] = useState(120000);

  const handleSave = (profile) => {
    setIncomeProfile(profile);
    console.log('Saved income profile:', profile);
  };

  const handleUpperIncomeLimitChange = (e) => {
    const newValue = parseInt(e.target.value) || 0;
    if (incomeProfile && incomeProfile.controlPoints) {
      const highestPoint = Math.max(
        ...incomeProfile.controlPoints.map((point) => point.y)
      );
      setUpperIncomeLimit(
        Math.max(highestPoint, Math.min(2000000, newValue))
      );
    } else {
      setUpperIncomeLimit(
        Math.max(100000, Math.min(2000000, newValue))
      );
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">Income Profile Editor</h1>
      <div className="flex mb-4 space-x-4">
        <div className="flex-1">
          <label
            htmlFor="timeHorizon"
            className="block text-sm font-medium text-gray-700"
          >
            Time Horizon (years)
          </label>
          <input
            type="number"
            id="timeHorizon"
            value={timeHorizon}
            onChange={(e) =>
              setTimeHorizon(Math.max(2, parseInt(e.target.value) || 0))
            }
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            min="2"
          />
        </div>
        <div className="flex-1">
          <label
            htmlFor="upperIncomeLimit"
            className="block text-sm font-medium text-gray-700"
          >
            Upper Income Input Limit
          </label>
          <input
            type="number"
            id="upperIncomeLimit"
            value={upperIncomeLimit}
            onChange={handleUpperIncomeLimitChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            min="100000"
            max="2000000"
            step="10000"
          />
        </div>
      </div>
      <button
        onClick={() => setIsModalOpen(true)}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Edit Income Profile
      </button>
      <ProfileEditorModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        initialValue={50000}
        maxValue={upperIncomeLimit}
        timeHorizon={timeHorizon}
        onSave={handleSave}
        savedProfile={incomeProfile}
      />
      {incomeProfile && (
        <div className="mt-4">
          <h2 className="text-xl font-bold">Saved Income Profile:</h2>
          <pre className="bg-gray-100 p-4 rounded mt-2">
            {JSON.stringify(incomeProfile, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
};

export default IncomeProfileEditor;
