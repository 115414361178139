import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'lucide-react';
import { Button } from '../../components/ui/button';
import Logo from './Logo';

const Header = () => {
  return (
    <header className="bg-white shadow-md">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <Logo className="w-48 h-12" /> {/* Updated size here */}
        </Link>
        <nav>
          <Button variant="ghost" className="lg:hidden">
            <Menu />
          </Button>
          <ul className="hidden lg:flex space-x-8">
            <li><Link to="/" className="text-gray-600 hover:text-gray-800 font-medium">Home</Link></li>
            <li><Link to="/about" className="text-gray-600 hover:text-gray-800 font-medium">About</Link></li>
            <li><Link to="/contact" className="text-gray-600 hover:text-gray-800 font-medium">Contact</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;