// DataEntryTab.js
import React from 'react';

const DataEntryTab = ({
  timeHorizon,
  timeBeforeRetirement,
  upperIncomeLimit,
  setUpperIncomeLimit,
  setIsIncomeProfileModalOpen,
  setIsTuneIncomeModalOpen,
  setIsViewIncomeModalOpen,
  originalIncomeProfile,
  adjustedIncomeProfile,
}) => {
  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4">Data Entry</h2>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Time Horizon (years)</label>
          <p className="mt-1 text-lg font-semibold">{timeHorizon}</p>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Time Before Retirement (years)
          </label>
          <p className="mt-1 text-lg font-semibold">{timeBeforeRetirement}</p>
        </div>
        <div className="bg-gray-100 rounded-lg p-4">
          <h3 className="text-lg font-semibold mb-2">Income Details</h3>
          <div>
            <label htmlFor="upperIncomeLimit" className="block text-sm font-medium text-gray-700">
              Upper Income Limit: ${upperIncomeLimit.toLocaleString()}
            </label>
            <input
              type="range"
              id="upperIncomeLimit"
              value={upperIncomeLimit}
              onChange={(e) => setUpperIncomeLimit(parseInt(e.target.value))}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer mt-2"
              min="120000"
              max="500000"
              step="20000"
            />
          </div>
          <div className="flex space-x-2 mt-4">
            <button
              onClick={() => setIsIncomeProfileModalOpen(true)}
              className={`flex-1 ${
                timeBeforeRetirement === 0
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-blue-500 hover:bg-blue-700'
              } text-white font-bold py-2 px-4 rounded`}
              disabled={timeBeforeRetirement === 0}
            >
              Edit Income Profile
            </button>
            <button
              onClick={() => setIsTuneIncomeModalOpen(true)}
              className={`flex-1 ${
                !adjustedIncomeProfile ? 'bg-gray-300 cursor-not-allowed' : 'bg-green-500 hover:bg-green-700'
              } text-white font-bold py-2 px-4 rounded`}
              disabled={!adjustedIncomeProfile}
            >
              Tune Income
            </button>
            <button
              onClick={() => setIsViewIncomeModalOpen(true)}
              className={`flex-1 ${
                !adjustedIncomeProfile ? 'bg-gray-300 cursor-not-allowed' : 'bg-yellow-500 hover:bg-yellow-700'
              } text-white font-bold py-2 px-4 rounded`}
              disabled={!adjustedIncomeProfile}
            >
              View Income
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataEntryTab;
