import React from 'react';

const InputField = ({ label, id, value, onChange, type = 'text' }) => (
  <div>
    <label htmlFor={id} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
    <input
      type={type}
      id={id}
      name={id}
      value={value}
      onChange={onChange}
      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
    />
  </div>
);

const SelectField = ({ label, id, value, onChange, options }) => (
  <div>
    <label htmlFor={id} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
    <select
      id={id}
      name={id}
      value={value}
      onChange={onChange}
      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
    >
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  </div>
);

const GeneralInformationTab = ({ generalInfo, onInfoChange }) => {
  const currentYear = new Date().getFullYear();
  const birthYearOptions = Array.from({ length: 101 }, (_, i) => currentYear - i);
  const retirementAgeOptions = Array.from({ length: 31 }, (_, i) => i + 50);
  const runoutAgeOptions = Array.from({ length: 106 - (currentYear - generalInfo.birthYear) }, (_, i) => i + (currentYear - generalInfo.birthYear));

  const handleChange = (field, value) => {
    onInfoChange({ ...generalInfo, [field]: value });
  };

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4">General Information</h2>
      <form className="space-y-4">
        <InputField
          label="Scenario Name"
          id="scenarioName"
          value={generalInfo.scenarioName}
          onChange={(e) => handleChange('scenarioName', e.target.value)}
        />
        <SelectField
          label="Birth Year"
          id="birthYear"
          value={generalInfo.birthYear}
          onChange={(e) => handleChange('birthYear', Number(e.target.value))}
          options={birthYearOptions}
        />
        <SelectField
          label="Retirement Age"
          id="retirementAge"
          value={generalInfo.retirementAge}
          onChange={(e) => handleChange('retirementAge', Number(e.target.value))}
          options={retirementAgeOptions}
        />
        <SelectField
          label="Runout Age"
          id="runoutAge"
          value={generalInfo.runoutAge}
          onChange={(e) => handleChange('runoutAge', Number(e.target.value))}
          options={runoutAgeOptions}
        />
      </form>
    </div>
  );
};

export default GeneralInformationTab;