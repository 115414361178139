import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// SVG component for the info icon
const InfoIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className}>
    <path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,21a9,9,0,1,1,9-9A9.011,9.011,0,0,1,12,21Z"/>
    <path d="M11.545,9.545h-.3A1.577,1.577,0,0,0,9.64,10.938,1.5,1.5,0,0,0,11,12.532v4.65a1.5,1.5,0,0,0,3,0V12A2.455,2.455,0,0,0,11.545,9.545Z"/>
    <path d="M11.83,8.466A1.716,1.716,0,1,0,10.114,6.75,1.715,1.715,0,0,0,11.83,8.466Z"/>
  </svg>
);

// Modal component for pop-ups
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
        <div className="flex justify-end">
          <button onClick={onClose} className="text-xl font-bold">&times;</button>
        </div>
        {children}
      </div>
    </div>
  );
};

// YMPE Data
const ympeData = {
  1966: 5000, 1967: 5000, 1968: 5100, 1969: 5200, 1970: 5300,
  1971: 5400, 1972: 5500, 1973: 5900, 1974: 6600, 1975: 7400,
  1976: 8300, 1977: 9300, 1978: 10400, 1979: 11700, 1980: 13100,
  1981: 14700, 1982: 16500, 1983: 18500, 1984: 20800, 1985: 23400,
  1986: 25800, 1987: 25900, 1988: 26500, 1989: 27700, 1990: 28900,
  1991: 30500, 1992: 32200, 1993: 33400, 1994: 34400, 1995: 34900,
  1996: 35400, 1997: 35800, 1998: 36900, 1999: 37400, 2000: 37600,
  2001: 38300, 2002: 39100, 2003: 39900, 2004: 40500, 2005: 41100,
  2006: 42100, 2007: 43700, 2008: 44900, 2009: 46300, 2010: 47200,
  2011: 48300, 2012: 50100, 2013: 51100, 2014: 52500, 2015: 53600,
  2016: 54900, 2017: 55300, 2018: 55900, 2019: 57400, 2020: 58700,
  2021: 61600, 2022: 64900, 2023: 66600, 2024: 68500,
};

const CPPQPPCalculator = () => {
  const currentYear = new Date().getFullYear();
  
  const [formData, setFormData] = useState({
    birthYear: '',
    birthMonth: '',
    province: 'canada',
    startAge: '65',
    startMonth: '0',
    includeChildRearing: false,
    childRearingStartYear: '',
    childRearingStartMonth: '',
    childRearingMonths: ''
  });
  const [incomeData, setIncomeData] = useState([]);
  const [showIncomeTable, setShowIncomeTable] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);
  const [calculationResult, setCalculationResult] = useState(null);
  const [modalContent, setModalContent] = useState(null);

  const handleInputChange = (name, value) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const openModal = (content) => {
    setModalContent(content);
  };

  const closeModal = () => {
    setModalContent(null);
  };

  const validateBirthYear = () => {
    const year = parseInt(formData.birthYear);
    if (isNaN(year) || year < currentYear - 100 || year > currentYear) {
      alert(`Please enter a year between ${currentYear - 100} and ${currentYear}`);
      return false;
    }
    return true;
  };

  const getYMPE = (year) => {
    return ympeData[year] || 0;
  };

  const getLatestYMPEYear = () => {
    return Math.max(...Object.keys(ympeData).map(Number));
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const generateIncomeTable = () => {
    if (!validateBirthYear()) return;
    
    const birthYear = parseInt(formData.birthYear);
    const startYear = birthYear + 18;
    const endYear = birthYear + parseInt(formData.startAge);
    const latestYMPEYear = getLatestYMPEYear();
    const newIncomeData = [];

    for (let year = startYear; year <= endYear; year++) {
      let earnings = year <= latestYMPEYear ? getYMPE(year) : getYMPE(latestYMPEYear);
      newIncomeData.push({
        year,
        age: year - birthYear,
        earnings: formatCurrency(earnings)
      });
    }

    setIncomeData(newIncomeData);
    setShowIncomeTable(true);
    setCalculationResult(null);
  };

  const handleEarningsChange = (index, value) => {
    const numericValue = value.replace(/[^0-9]/g, '');
    const formattedValue = formatCurrency(numericValue);

    const updatedIncomeData = [...incomeData];
    updatedIncomeData[index].earnings = formattedValue;
    setIncomeData(updatedIncomeData);
  };

  const getMonthOptions = (age) => {
    if (age === '70') return [0];
    return Array.from({ length: 12 }, (_, i) => i);
  };

  const calculateBenefit = async () => {
    setIsCalculating(true);
    try {
      const response = await fetch('https://v645ypkb99.execute-api.ca-central-1.amazonaws.com/Prod/cpp-qpp-calculate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          birthYear: parseInt(formData.birthYear),
          birthMonth: parseInt(formData.birthMonth),
          retirementAge: parseInt(formData.startAge),
          retirementMonth: parseInt(formData.startMonth),
          program: formData.province === 'quebec' ? 'QPP' : 'CPP',
          includeChildRearing: formData.includeChildRearing,
          childRearingStartYear: parseInt(formData.childRearingStartYear),
          childRearingStartMonth: parseInt(formData.childRearingStartMonth),
          childRearingMonths: parseInt(formData.childRearingMonths),
          earnings: incomeData.reduce((acc, item) => {
            acc[item.year] = parseFloat(item.earnings.replace(/[^0-9.-]+/g,""));
            return acc;
          }, {})
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setCalculationResult(result);
    } catch (error) {
      console.error('Error:', error);
      alert('There was an error calculating the benefit. Please try again.');
    } finally {
      setIsCalculating(false);
    }
  };

  return (
    <div className="w-full max-w-[420px] mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="bg-blue-600 text-white p-4">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-xl font-bold">CPP/QPP Calculator</h2>
          <Link to="/dashboard" className="text-white hover:underline">Back</Link>
        </div>
        <button
          className="flex items-center text-sm"
          onClick={() => openModal(
            <div>
              <h3 className="text-lg font-bold mb-2">About this calculator</h3>
              <p>This calculator helps you estimate your CPP/QPP benefits based on your contributions and other factors.</p>
            </div>
          )}
        >
          <InfoIcon className="w-4 h-4 mr-1 fill-current" />
          About this calculator
        </button>
      </div>
      <div className="p-4">
        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="birthYear" className="block text-sm font-medium text-gray-700 mb-1">Birth Year</label>
              <input
                id="birthYear"
                type="number"
                value={formData.birthYear}
                onChange={(e) => handleInputChange('birthYear', e.target.value)}
                onBlur={validateBirthYear}
                min={currentYear - 100}
                max={currentYear}
                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label htmlFor="birthMonth" className="block text-sm font-medium text-gray-700 mb-1">Birth Month</label>
              <select
                id="birthMonth"
                value={formData.birthMonth}
                onChange={(e) => handleInputChange('birthMonth', e.target.value)}
                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select</option>
                {[...Array(12)].map((_, i) => (
                  <option key={i} value={(i + 1).toString()}>
                    {new Date(0, i).toLocaleString('default', { month: 'short' })}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div>
            <label htmlFor="province" className="block text-sm font-medium text-gray-700 mb-1">Program</label>
            <select
              id="province"
              value={formData.province}
              onChange={(e) => handleInputChange('province', e.target.value)}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
            >
              <option value="canada">CPP</option>
              <option value="quebec">QPP</option>
            </select>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="startAge" className="block text-sm font-medium text-gray-700 mb-1">Retirement Start Age</label>
              <select
                id="startAge"
                value={formData.startAge}
                onChange={(e) => {
                  handleInputChange('startAge', e.target.value);
                  handleInputChange('startMonth', '0'); // Reset month when age changes
                }}
                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              >
                {[60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70].map((age) => (
                  <option key={age} value={age.toString()}>{age}</option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="startMonth" className="block text-sm font-medium text-gray-700 mb-1">Start Month</label>
              <select
                id="startMonth"
                value={formData.startMonth}
                onChange={(e) => handleInputChange('startMonth', e.target.value)}
                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              >
                {getMonthOptions(formData.startAge).map((month) => (
                  <option key={month} value={month.toString()}>{month}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="relative">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={formData.includeChildRearing}
                onChange={(e) => handleInputChange('includeChildRearing', e.target.checked)}
                className="mr-2"
              />
              Include Child Rearing Period
            </label>
            <button
              className="absolute right-2 top-0 cursor-pointer text-blue-500"
              onClick={() => openModal(
                <div>
                  <h3 className="text-lg font-bold mb-2">Child Rearing Period</h3>
                  <p>The child-rearing provision can increase your CPP/QPP benefit if you stopped working or worked less to raise your children.</p>
                </div>
              )}
            >
              <InfoIcon className="w-4 h-4 fill-current" />
            </button>
          </div>
          {formData.includeChildRearing && (
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="childRearingStartYear">Start Year</label>
                <input
                  id="childRearingStartYear"
                  type="number"
                  value={formData.childRearingStartYear}
                  onChange={(e) => handleInputChange('childRearingStartYear', e.target.value)}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label htmlFor="childRearingStartMonth">Start Month</label>
                <select
                  id="childRearingStartMonth"
                  value={formData.childRearingStartMonth}
                  onChange={(e) => handleInputChange('childRearingStartMonth', e.target.value)}
                  className="w-full p-2 border rounded"
                >
                  <option value="">Select</option>
                  {[...Array(12)].map((_, i) => (
                    <option key={i} value={(i + 1).toString()}>
                      {new Date(0, i).toLocaleString('default', { month: 'short' })}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-2">
                <label htmlFor="childRearingMonths">Number of Months</label>
                <input
                  id="childRearingMonths"
                  type="number"
                  value={formData.childRearingMonths}
                  onChange={(e) => handleInputChange('childRearingMonths', e.target.value)}
                  className="w-full p-2 border rounded"
                />
              </div>
            </div>
          )}
        </div>

        <div className="mt-6 bg-gray-100 p-4 rounded">
          <div className="flex justify-between items-center mb-2">
            <button 
              onClick={generateIncomeTable}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              {showIncomeTable ? "Regenerate Table" : "Enter Income"}
            </button>
            <button
              onClick={() => openModal(
                <div>
                  <h3 className="text-lg font-bold mb-2">Enter Income</h3>
                  <p>Click this button to generate or regenerate the income table based on your birth year and retirement age.</p>
                </div>
              )}
              className="text-blue-500 hover:text-blue-600"
            >
              <InfoIcon className="w-6 h-6 fill-current" />
            </button>
          </div>
        </div>

        <div className="mt-4 bg-gray-100 p-4 rounded">
          <div className="flex justify-between items-center mb-2">
            <button 
              onClick={calculateBenefit} 
              disabled={!showIncomeTable || isCalculating}
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
            >
              {isCalculating ? "Calculating..." : "Calculate Benefit"}
            </button>
            <button
              onClick={() => openModal(
                <div>
                  <h3 className="text-lg font-bold mb-2">Calculate Benefit</h3>
                  <p>Click this button to calculate your estimated CPP/QPP benefits based on the income data you've entered.</p>
                </div>
              )}
              className="text-blue-500 hover:text-blue-600"
            >
              <InfoIcon className="w-6 h-6 fill-current" />
            </button>
          </div>
        </div>

        {calculationResult && (
          <div className="mt-4 p-4 bg-gray-100 rounded">
            <h3 className="text-lg font-semibold">Calculation Result</h3>
            <p>Base Benefit: {formatCurrency(calculationResult.baseBenefit)}</p>
            <p>Enhanced Benefit Part 2: {formatCurrency(calculationResult.enhancedBenefitPart2)}</p>
            <p>Enhanced Benefit Part 3: {formatCurrency(calculationResult.enhancedBenefitPart3)}</p>
            <p>Total Benefit before adjustment: {formatCurrency(calculationResult.totalBenefitBeforeAdjustment)}</p>
            <p className="font-bold">Final Adjusted Total Benefit: {formatCurrency(calculationResult.finalAdjustedTotalBenefit)}</p>
          </div>
        )}

        {showIncomeTable && (
          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-2 px-2 py-1 bg-gray-100">
              {formData.province === 'quebec' ? 'QPP' : 'CPP'} Earnings
            </h3>
            <div className="overflow-x-auto">
              <table className="w-full table-auto">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="px-2 py-1 text-left text-xs">Year</th>
                    <th className="px-2 py-1 text-left text-xs">Age</th>
                    <th className="px-2 py-1 text-left text-xs">Earnings</th>
                  </tr>
                </thead>
                <tbody className="text-gray-700">
                  {incomeData.map((row, index) => (
                    <tr key={row.year} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                      <td className="px-2 py-1 text-xs">{row.year}</td>
                      <td className="px-2 py-1 text-xs">{row.age}</td>
                      <td className="px-2 py-1">
                        <input
                          type="text"
                          value={row.earnings}
                          onChange={(e) => handleEarningsChange(index, e.target.value)}
                          className="w-full p-1 text-xs border rounded focus:ring-1 focus:ring-blue-500"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      <Modal isOpen={!!modalContent} onClose={closeModal}>
        {modalContent}
      </Modal>
    </div>
  );
};

export default CPPQPPCalculator;