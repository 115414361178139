import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Card, CardHeader, CardContent } from '../../../components/ui/card';
import { Calculator } from 'lucide-react';
import CalculatorForm from '../../../components/calculators/CalculatorForm';
import ResultDisplay from '../../../components/calculators/ResultDisplay';
import DetailsModal from '../../../components/calculators/DetailsModal';
import InfoCollapsible from '../../../components/calculators/InfoCollapsible';
import MathFormulaDisplay from '../../../components/calculators/MathFormulaDisplay';
import useCalculator from '../../../hooks/useCalculator';
import Layout from '../../dashboard/Layout';
import useAdVisibility from '../../../hooks/useAdVisibility';
import AdSpace from '../../../components/ui/AdSpace';
import Breadcrumbs from '../../../components/ui/Breadcrumbs';
import { calculateAmortizationSchedule, formatCurrency } from '../../../utils';

const loanInputs = [
  { name: 'principal', label: 'Loan Amount ($)', type: 'text', placeholder: 'Enter loan amount', required: true },
  { name: 'interestRate', label: 'Annual Interest Rate (%)', type: 'number', placeholder: 'Enter annual interest rate', required: true },
  { name: 'loanTerm', label: 'Loan Term (years)', type: 'number', placeholder: 'Enter loan term in years', required: true },
  { name: 'repaymentFrequency', label: 'Repayment Frequency', type: 'select', placeholder: 'Select frequency', required: true, options: [
    { value: 'weekly', label: 'Weekly' },
    { value: 'biweekly', label: 'Bi-weekly' },
    { value: 'monthly', label: 'Monthly' },
  ]},
];

const LoanAmortization = () => {
  const [amortizationSchedule, setAmortizationSchedule] = useState([]);
  const {
    formState,
    setFormState,
    result,
    isModalOpen,
    handleSubmit: originalHandleSubmit,
    openDetailsModal,
    closeDetailsModal,
  } = useCalculator({
    principal: '',
    interestRate: '',
    loanTerm: '',
    repaymentFrequency: 'monthly',
  }, 'loanAmortization');

  const { showAds, adSpaceClass } = useAdVisibility();

  const handleSubmit = (data) => {
    originalHandleSubmit(data);
    const schedule = calculateAmortizationSchedule(
      parseFloat(data.principal),
      parseFloat(data.interestRate),
      parseFloat(data.loanTerm),
      data.repaymentFrequency
    );
    setAmortizationSchedule(schedule);
  };

  const loanFormula = `\\text{Payment} = \\frac{P \\cdot \\frac{r}{n}}{1 - \\left(1 + \\frac{r}{n}\\right)^{-n \\cdot t}}`;

  const formattedResult = result ? {
    [`${result.frequency.charAt(0).toUpperCase() + result.frequency.slice(1)} Payment`]: formatCurrency(result.payment),
    'Total Interest': formatCurrency(result.totalInterest),
    'Total Payment': formatCurrency(result.totalPayment)
  } : null;

  const breadcrumbItems = [
    { label: 'Home', path: '/' },
    { label: 'Financial Utilities', path: '/financial-utilities' },
    { label: 'Loan Amortization Calculator', path: '/financial-utilities/loan-amortization' },
  ];

  return (
    <Layout breadcrumbs={<Breadcrumbs items={breadcrumbItems} />}>
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col lg:flex-row justify-between lg:space-x-4">
          {showAds && (
            <AdSpace width="1/6" height="[600px]" className="hidden lg:block lg:w-1/6" />
          )}

          <Card className="w-full lg:w-2/3 max-w-3xl mx-auto">
            <CardHeader className="flex flex-row items-center space-x-4 p-6">
              <Calculator className="w-8 h-8 text-blue-500" />
              <h2 className="text-2xl font-semibold text-gray-800">Loan Amortization Calculator</h2>
            </CardHeader>
            <CardContent>
              <CalculatorForm 
                inputs={loanInputs} 
                onSubmit={handleSubmit}
                formState={formState}
                setFormState={setFormState}
              />
              {formattedResult && (
                <ResultDisplay result={formattedResult} onDetailsClick={openDetailsModal} />
              )}
              <DetailsModal 
                isOpen={isModalOpen} 
                onClose={closeDetailsModal} 
                data={{
                  ...formState,
                  ...(formattedResult ? {
                    monthlyPayment: formattedResult[`${result.frequency.charAt(0).toUpperCase() + result.frequency.slice(1)} Payment`],
                    totalInterest: formattedResult['Total Interest'],
                    totalPayment: formattedResult['Total Payment']
                  } : {})
                }}
                amortizationSchedule={amortizationSchedule}
              />
              
              <InfoCollapsible 
                title="About Loan Amortization"
                content={
                  <>
                    <h3 className="text-xl font-semibold mb-2">Understanding Loan Amortization</h3>
                    <p>
                      Loan amortization is the process of paying off a debt over time through regular payments. This calculator helps you understand how your loan payments are applied to the principal balance and interest over the life of the loan.
                    </p>
                    <MathFormulaDisplay formula={loanFormula} description="Loan Payment Formula" />
                    <p>
                      Where: <br />
                      P = Principal loan amount <br />
                      r = Annual interest rate (as a decimal) <br />
                      n = Number of payments per year <br />
                      t = Total number of years
                    </p>
                    <h4 className="text-lg font-semibold mt-4 mb-2">Key Features of Our Calculator:</h4>
                    <ul className="list-disc list-inside mt-1 mb-4">
                      <li>Calculate payments for weekly, bi-weekly, or monthly frequencies</li>
                      <li>Determine total interest paid over the life of the loan</li>
                      <li>View the total cost of the loan (principal + interest)</li>
                      <li>Adjust calculations based on different loan terms and interest rates</li>
                      <li>Generate a detailed amortization schedule</li>
                    </ul>
                    <h4 className="text-lg font-semibold mt-4 mb-2">Why Loan Amortization Matters:</h4>
                    <p>
                      Understanding loan amortization is crucial for making informed financial decisions. It helps you:
                    </p>
                    <ul className="list-disc list-inside mt-1 mb-4">
                      <li>Plan your budget more effectively by knowing your exact payment amounts</li>
                      <li>Understand how much of each payment goes towards interest vs. principal</li>
                      <li>Identify opportunities to save on interest by making extra payments</li>
                      <li>Compare different loan offers to find the best terms for your financial situation</li>
                      <li>Visualize the long-term cost of your loan beyond just the monthly payment</li>
                    </ul>
                    <h4 className="text-lg font-semibold mt-4 mb-2">Types of Loans This Calculator Can Help With:</h4>
                    <ul className="list-disc list-inside mt-1 mb-4">
                      <li>Mortgage loans</li>
                      <li>Auto loans</li>
                      <li>Personal loans</li>
                      <li>Student loans</li>
                      <li>Home equity loans</li>
                    </ul>
                    <p className="mt-2">
                      Whether you're a first-time homebuyer, a student considering loans, or someone looking to refinance existing debt, our Loan Amortization Calculator provides the insights you need to take control of your financial future.
                    </p>
                    <p className="mt-2">
                      Remember, while this calculator provides valuable estimates, it's always recommended to consult with a financial advisor for personalized advice tailored to your specific situation.
                    </p>
                  </>
                }
              />
              
              {showAds && (
                <AdSpace width="full" height="[200px]" className="block lg:hidden mt-8" />
              )}
            </CardContent>
          </Card>

          {showAds && (
            <AdSpace width="1/6" height="[600px]" className="hidden lg:block lg:w-1/6" />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default LoanAmortization;