// src/hooks/useCalculator.js
import { useState } from 'react';
import { calculateLoan, calculatePresentValueAnnuity } from '../utils';

const useCalculator = (initialState, calculatorType) => {
  const [formState, setFormState] = useState(initialState);
  const [result, setResult] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = (data) => {
    let calculationResult;
    switch (calculatorType) {
      case 'loanAmortization':
        calculationResult = calculateLoan(data);
        break;
      case 'presentValueAnnuity':
        calculationResult = calculatePresentValueAnnuity(
          parseFloat(data.payment),
          parseFloat(data.interestRate),
          parseInt(data.periods),
          data.paymentFrequency
        );
        break;
      // Add more cases for future calculators here
      default:
        console.error('Unknown calculator type:', calculatorType);
        return;
    }
    setResult(calculationResult);
  };

  const openDetailsModal = () => setIsModalOpen(true);
  const closeDetailsModal = () => setIsModalOpen(false);

  return {
    formState,
    setFormState,
    result,
    isModalOpen,
    handleSubmit,
    openDetailsModal,
    closeDetailsModal,
  };
};

export default useCalculator;