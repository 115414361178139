import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Card, CardHeader, CardContent } from '../../../components/ui/card';
import { Calculator } from 'lucide-react';
import CalculatorForm from '../../../components/calculators/CalculatorForm';
import ResultDisplay from '../../../components/calculators/ResultDisplay';
import DetailsModal from '../../../components/calculators/DetailsModal';
import InfoCollapsible from '../../../components/calculators/InfoCollapsible';
import MathFormulaDisplay from '../../../components/calculators/MathFormulaDisplay';
import useCalculator from '../../../hooks/useCalculator';
import Layout from '../../dashboard/Layout';
import useAdVisibility from '../../../hooks/useAdVisibility';
import AdSpace from '../../../components/ui/AdSpace';
import Breadcrumbs from '../../../components/ui/Breadcrumbs';
import { generateAnnuitySchedule, formatCurrency } from '../../../utils';

const annuityInputs = [
  { name: 'payment', label: 'Periodic Payment ($)', type: 'text', placeholder: 'Enter payment amount', required: true },
  { name: 'interestRate', label: 'Annual Interest Rate (%)', type: 'number', placeholder: 'Enter annual interest rate', required: true },
  { name: 'periods', label: 'Number of Periods', type: 'number', placeholder: 'Enter number of periods', required: true },
  { name: 'paymentFrequency', label: 'Payment Frequency', type: 'select', placeholder: 'Select frequency', required: true, options: [
    { value: 'annually', label: 'Annually' },
    { value: 'semiannually', label: 'Semi-annually' },
    { value: 'quarterly', label: 'Quarterly' },
    { value: 'monthly', label: 'Monthly' },
  ]},
];

const PresentValueAnnuity = () => {
  const [annuitySchedule, setAnnuitySchedule] = useState([]);
  const {
    formState,
    setFormState,
    result,
    isModalOpen,
    handleSubmit: originalHandleSubmit,
    openDetailsModal,
    closeDetailsModal,
  } = useCalculator({
    payment: '',
    interestRate: '',
    periods: '',
    paymentFrequency: 'annually',
  }, 'presentValueAnnuity');

  const { showAds, adSpaceClass } = useAdVisibility();

  const handleSubmit = (data) => {
    originalHandleSubmit(data);
    const schedule = generateAnnuitySchedule(
      parseFloat(data.payment),
      parseFloat(data.interestRate),
      parseInt(data.periods),
      data.paymentFrequency
    );
    setAnnuitySchedule(schedule);
  };

  const annuityFormula = `PV = PMT \\left( \\frac{1}{r} - \\frac{1}{r(1+r)^t} \\right)`;

  const formattedResult = result ? {
    'Present Value': formatCurrency(result.presentValue),
    'Total Future Payments': formatCurrency(result.totalPayments),
    'Payment Frequency': result.frequency.charAt(0).toUpperCase() + result.frequency.slice(1)
  } : null;

  const breadcrumbItems = [
    { label: 'Home', path: '/' },
    { label: 'Financial Utilities', path: '/financial-utilities' },
    { label: 'Present Value of Annuity Calculator', path: '/financial-utilities/present-value-annuity' },
  ];

  return (
    <Layout breadcrumbs={<Breadcrumbs items={breadcrumbItems} />}>
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col lg:flex-row justify-between lg:space-x-4">
          {showAds && (
            <AdSpace width="1/6" height="[600px]" className="hidden lg:block lg:w-1/6" />
          )}

          <Card className="w-full lg:w-2/3 max-w-3xl mx-auto">
            <CardHeader className="flex flex-row items-center space-x-4 p-6">
              <Calculator className="w-8 h-8 text-blue-500" />
              <h2 className="text-2xl font-semibold text-gray-800">Present Value of Annuity Calculator</h2>
            </CardHeader>
            <CardContent>
              <CalculatorForm 
                inputs={annuityInputs} 
                onSubmit={handleSubmit}
                formState={formState}
                setFormState={setFormState}
              />
              {formattedResult && (
                <ResultDisplay result={formattedResult} onDetailsClick={openDetailsModal} />
              )}
              <DetailsModal 
                isOpen={isModalOpen} 
                onClose={closeDetailsModal} 
                data={{
                  ...formState,
                  ...(formattedResult || {})
                }}
                amortizationSchedule={annuitySchedule}
              />
              
              <InfoCollapsible 
                title="About Present Value of Annuity"
                content={
                  <>
                    <h3 className="text-xl font-semibold mb-2">Understanding Present Value of Annuity</h3>
                    <p>
                      The Present Value of an Annuity is a financial concept that calculates the current value of a series of future equal payments, discounted at a specified rate of return or interest rate.
                    </p>
                    <MathFormulaDisplay formula={annuityFormula} description="Present Value of Annuity Formula" />
                    <p>
                      Where: <br />
                      PV = Present Value <br />
                      PMT = Payment amount per period <br />
                      r = Interest rate per period (as a decimal) <br />
                      t = Number of periods
                    </p>
                    <h4 className="text-lg font-semibold mt-4 mb-2">Key Features of Our Calculator:</h4>
                    <ul className="list-disc list-inside mt-1 mb-4">
                      <li>Calculate the present value for various payment frequencies (annually, semi-annually, quarterly, monthly)</li>
                      <li>Determine the total future payments over the annuity period</li>
                      <li>Compare the present value to the total future payments</li>
                      <li>Generate a detailed schedule showing the present value of each payment</li>
                    </ul>
                    <h4 className="text-lg font-semibold mt-4 mb-2">Why Present Value of Annuity Matters:</h4>
                    <p>
                      Understanding the present value of an annuity is crucial for various financial decisions:
                    </p>
                    <ul className="list-disc list-inside mt-1 mb-4">
                      <li>Evaluating pension or retirement plan options</li>
                      <li>Assessing the value of lottery winnings paid over time</li>
                      <li>Comparing different investment opportunities</li>
                      <li>Determining the lump sum needed to fund future periodic payments</li>
                      <li>Understanding the time value of money in financial planning</li>
                    </ul>
                    <h4 className="text-lg font-semibold mt-4 mb-2">Applications of this Calculator:</h4>
                    <ul className="list-disc list-inside mt-1 mb-4">
                      <li>Retirement planning</li>
                      <li>Investment analysis</li>
                      <li>Loan and mortgage evaluation</li>
                      <li>Business valuation</li>
                      <li>Financial decision-making in various contexts</li>
                    </ul>
                    <p className="mt-2">
                      Whether you're a financial professional, a student of finance, or an individual making important financial decisions, our Present Value of Annuity Calculator provides valuable insights into the time value of money and helps you make informed choices about future cash flows.
                    </p>
                    <p className="mt-2">
                      Remember, while this calculator offers precise mathematical calculations, it's always advisable to consult with a qualified financial advisor for personalized advice tailored to your specific financial situation and goals.
                    </p>
                  </>
                }
              />
              
              {showAds && (
                <AdSpace width="full" height="[200px]" className="block lg:hidden mt-8" />
              )}
            </CardContent>
          </Card>

          {showAds && (
            <AdSpace width="1/6" height="[600px]" className="hidden lg:block lg:w-1/6" />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default PresentValueAnnuity;