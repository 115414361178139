import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Card, CardHeader, CardContent, CardFooter } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { Calculator, PiggyBank, LineChart, ArrowRight, DollarSign, Wrench } from 'lucide-react';
import Layout from './Layout';
import { AuthContext } from '../../contexts/AuthContext';

const MotionCard = motion(Card);

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 }
};

const Dashboard = () => {
  const { user } = useContext(AuthContext);

  const cards = [
    {
      title: 'Financial Utilities',
      description: 'Access a collection of handy financial calculators to assist with various aspects of your financial planning.',
      icon: <Wrench className="w-8 h-8 text-orange-500" />,
      link: '/financial-utilities',
      buttonText: 'View Utilities',
      color: 'from-orange-400 to-orange-600'
    },
    {
      title: 'CPP/QPP Calculator',
      description: 'Use our advanced calculator to get an accurate estimate of your Canada Pension Plan or Quebec Pension Plan benefits.',
      icon: <Calculator className="w-8 h-8 text-blue-500" />,
      link: '/cpp-qpp-calculator',
      buttonText: 'Open Calculator',
      color: 'from-blue-400 to-blue-600'
    },
    {
      title: 'Savings Optimizer',
      description: 'Discover the optimal balance between saving for the future and enjoying life today with our intelligent optimizer.',
      icon: <PiggyBank className="w-8 h-8 text-green-500" />,
      link: '/savings-optimizer',
      buttonText: 'Open Optimizer',
      color: 'from-green-400 to-green-600'
    },
    {
      title: 'Retirement Planner',
      description: 'Visualize your retirement journey and make informed decisions about your financial future.',
      icon: <LineChart className="w-8 h-8 text-purple-500" />,
      link: '/retirement-planner',
      buttonText: 'Coming Soon',
      color: 'from-purple-400 to-purple-600',
      disabled: true
    },
    {
      title: 'Financial Planner',
      description: 'Create detailed financial plans for your retirement, considering various income sources and expenses.',
      icon: <DollarSign className="w-8 h-8 text-emerald-500" />,
      link: '/financial-planner',
      buttonText: 'Open Planner',
      color: 'from-emerald-400 to-emerald-600'
    }
  ];

  return (
    <Layout>
      <div className="container mx-auto px-4 py-12">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <h1 className="text-3xl sm:text-3xl md:text-4xl font-bold mb-4 text-gray-800">Financial Tools for Canadian Retirees</h1>
        </motion.div>
        
        {user && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            className="text-center mb-8 text-lg font-medium text-gray-700"
          >
            Welcome back, {user.name}!
          </motion.p>
        )}
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {cards.map((card, index) => (
            <MotionCard
              key={card.title}
              className="flex flex-col overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300"
              variants={cardVariants}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.3, delay: index * 0.1 }}
            >
              <div className={`h-2 bg-gradient-to-r ${card.color}`} />
              <CardHeader className="flex flex-row items-center space-x-4 pb-2">
                {card.icon}
                <h3 className="text-2xl font-semibold text-gray-800">{card.title}</h3>
              </CardHeader>
              <CardContent className="flex-grow">
                <p className="text-gray-600">{card.description}</p>
              </CardContent>
              <CardFooter className="mt-auto">
                <Button
                  asChild={!card.disabled}
                  variant={card.disabled ? "secondary" : "default"}
                  className={`w-full ${card.disabled ? 'opacity-50 cursor-not-allowed' : `bg-gradient-to-r ${card.color} hover:opacity-90`}`}
                  disabled={card.disabled}
                >
                  {card.disabled ? (
                    <span className="inline-flex items-center justify-center">
                      {card.buttonText} <ArrowRight className="ml-2 h-4 w-4" />
                    </span>
                  ) : (
                    <Link to={card.link} className="inline-flex items-center justify-center">
                      {card.buttonText} <ArrowRight className="ml-2 h-4 w-4" />
                    </Link>
                  )}
                </Button>
              </CardFooter>
            </MotionCard>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;