import React from 'react';

const ResultsTab = ({ handleCalculate, adjustedIncomeProfile }) => {
  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4">Results</h2>
      <div className="space-y-4">
        <button
          onClick={handleCalculate}
          className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          disabled={!adjustedIncomeProfile}
        >
          Calculate
        </button>
        <div className="bg-gray-100 rounded-lg p-4">
          <h3 className="text-lg font-semibold mb-2">Income Projection Chart</h3>
          <div className="h-64 bg-white border border-gray-300 rounded-lg flex items-center justify-center">
            <p className="text-gray-500">Chart will be displayed here</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultsTab;