// src/contexts/ApiContext.js
import React, { createContext } from 'react';
import { useApi } from '../services/api';

export const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  const api = useApi();

  return (
    <ApiContext.Provider value={{ api }}>
      {children}
    </ApiContext.Provider>
  );
};