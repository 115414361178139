import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-US', { 
    style: 'currency', 
    currency: 'USD', 
    minimumFractionDigits: 2,
    maximumFractionDigits: 2 
  }).format(value);
};

export const calculateLoan = (data) => {
  const p = parseFloat(data.principal);
  const r = parseFloat(data.interestRate) / 100 / 12;
  const n = parseFloat(data.loanTerm) * 12;

  let periodsPerYear, totalPeriods, periodicRate;
  
  switch (data.repaymentFrequency) {
    case 'weekly':
      periodsPerYear = 52;
      break;
    case 'biweekly':
      periodsPerYear = 26;
      break;
    default: // monthly
      periodsPerYear = 12;
  }
  
  totalPeriods = n * (periodsPerYear / 12);
  periodicRate = r * (12 / periodsPerYear);

  const payment = (p * periodicRate * Math.pow(1 + periodicRate, totalPeriods)) / (Math.pow(1 + periodicRate, totalPeriods) - 1);
  const totalPayment = payment * totalPeriods;
  const totalInterest = totalPayment - p;

  return {
    payment: payment,
    totalInterest: totalInterest,
    totalPayment: totalPayment,
    frequency: data.repaymentFrequency
  };
};

export const calculateAmortizationSchedule = (principal, annualRate, termYears, frequency) => {
  const periodsPerYear = frequency === 'monthly' ? 12 : frequency === 'biweekly' ? 26 : 52;
  const totalPeriods = termYears * periodsPerYear;
  const periodicRate = annualRate / 100 / periodsPerYear;
  const payment = (principal * periodicRate * Math.pow(1 + periodicRate, totalPeriods)) / (Math.pow(1 + periodicRate, totalPeriods) - 1);

  let balance = principal;
  const schedule = [];

  for (let period = 1; period <= totalPeriods; period++) {
    const interest = balance * periodicRate;
    const principalPaid = payment - interest;
    balance -= principalPaid;

    schedule.push({
      period,
      payment: formatCurrency(payment),
      interest: formatCurrency(interest),
      principal: formatCurrency(principalPaid),
      balance: formatCurrency(Math.max(0, balance))
    });

    if (balance <= 0) break;
  }

  return schedule;
};

export const calculatePresentValueAnnuity = (payment, annualRate, periods, frequency) => {
  let periodsPerYear;
  switch (frequency) {
    case 'monthly':
      periodsPerYear = 12;
      break;
    case 'quarterly':
      periodsPerYear = 4;
      break;
    case 'semiannually':
      periodsPerYear = 2;
      break;
    default: // annually
      periodsPerYear = 1;
  }

  const r = annualRate / 100 / periodsPerYear;
  const t = periods;

  const presentValue = payment * ((1 - Math.pow(1 + r, -t)) / r);

  return {
    presentValue: presentValue,
    totalPayments: payment * t,
    frequency: frequency
  };
};

export const generateAnnuitySchedule = (payment, annualRate, periods, frequency) => {
  let periodsPerYear;
  switch (frequency) {
    case 'monthly':
      periodsPerYear = 12;
      break;
    case 'quarterly':
      periodsPerYear = 4;
      break;
    case 'semiannually':
      periodsPerYear = 2;
      break;
    default: // annually
      periodsPerYear = 1;
  }

  const r = annualRate / 100 / periodsPerYear;
  const schedule = [];
  let presentValue = 0;

  for (let period = 1; period <= periods; period++) {
    const discountFactor = 1 / Math.pow(1 + r, period);
    const pvOfPayment = payment * discountFactor;
    presentValue += pvOfPayment;

    schedule.push({
      period,
      payment: formatCurrency(payment),
      presentValueOfPayment: formatCurrency(pvOfPayment),
      cumulativePresentValue: formatCurrency(presentValue)
    });
  }

  return schedule;
};