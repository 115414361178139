// src/features/dashboard/Layout.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children, breadcrumbs }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      {breadcrumbs && (
        <div className="bg-gray-100 py-2 px-4">
          {breadcrumbs}
        </div>
      )}
      <main className="flex-grow">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;