// src/hooks/useAdVisibility.js
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

const useAdVisibility = () => {
  const { user } = useContext(AuthContext);
  const isSubscribed = user && user.isSubscribed;

  return {
    showAds: !isSubscribed,
    adSpaceClass: isSubscribed ? 'hidden' : 'block',
  };
};

export default useAdVisibility;