import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { ApiProvider } from './contexts/ApiContext';
import Dashboard from './features/dashboard/Dashboard';
import HelloWorld from './features/misc/HelloWorld';
import CPPQPPCalculator from './features/calculators/CPPQPPCalculator';
import HealthCheck from './features/misc/HealthCheck';
import SavingsOptimizer from './features/calculators/SavingsOptimizer';
import FinancialPlanner from './features/calculators/financialPlanner/FinancialPlanner';
import FinancialUtilities from './features/calculators/financialUtilities/FinancialUtilities';
import LoanAmortization from './features/calculators/financialUtilities/LoanAmortization';
import PresentValueAnnuity from './features/calculators/financialUtilities/PresentValueAnnuity';

const App = () => {
  return (
    <AuthProvider>
      <ApiProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/hello-world" element={<HelloWorld />} />
            <Route path="/cpp-qpp-calculator" element={<CPPQPPCalculator />} />
            <Route path="/health-check" element={<HealthCheck />} />
            <Route path="/savings-optimizer" element={<SavingsOptimizer />} />
            <Route path="/financial-planner" element={<FinancialPlanner />} />
            <Route path="/financial-utilities" element={<FinancialUtilities />} />
            <Route path="/loan-amortization" element={<LoanAmortization />} />
            <Route path="/present-value-annuity" element={<PresentValueAnnuity />} />
          </Routes>
        </Router>
      </ApiProvider>
    </AuthProvider>
  );
};

export default App;