// src/components/calculators/InfoCollapsible.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '../ui/collapsible';
import { ChevronDown, Info } from 'lucide-react';

const InfoCollapsible = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className="mt-6 border-t pt-4"
    >
      <CollapsibleTrigger className="flex items-center justify-between w-full p-2 bg-blue-50 hover:bg-blue-100 transition-colors duration-200 rounded-lg group">
        <span className="text-lg font-semibold text-gray-700 flex items-center">
          <Info className="mr-2 h-5 w-5 text-blue-500" />
          {title}
        </span>
        <motion.div
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <ChevronDown className="h-5 w-5 text-gray-500 group-hover:text-blue-500" />
        </motion.div>
      </CollapsibleTrigger>
      <CollapsibleContent className="mt-4 text-gray-600">
        {content}
      </CollapsibleContent>
    </Collapsible>
  );
};

export default InfoCollapsible;